import Button from '@atoms/Button/Button'
import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import { mapCurrency } from '@remoteam-front/utils/currency'
import { approveFlowPayroll, getPayrollsTotal } from '@services/payroll.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { MultiCurrencyExpanded } from './multi-currency-expanded.component'

const Item = ({
  company,
  expandData,
  totalAmount,
  totalAmountInPartnerInvoicingCurrency,
  localCurrencyCode,
  invoiceCurrencyCode,
  localCurrency,
  invoiceCurrency,
  setExpandData,
  refetch,
}) => {
  const [checkedPayrolls, setCheckedPayrolls] = useState(null)
  const [checkedPayrollsAll, setCheckedPayrollsAll] = useState(true)
  const isOpen = expandData?.company_id === company?.company_id

  const approvePayrollRequest = useMutation('approveFlowPayroll', {
    mutationFn: (payload) => approveFlowPayroll(payload),
    onSuccess: () => {
      toast.success('Successfully updated')
      setCheckedPayrolls(null)
      refetch()
    },
  })
  const handleClick = (company) => () => setExpandData(isOpen ? null : company)
  const handleClickApprove = () => {
    if (checkedPayrolls) {
      approvePayrollRequest.mutate({
        payrolls: Object.keys(checkedPayrolls),
      })
    }
  }

  const handleCheckCountPayroll = () => {
    if (checkedPayrolls) {
      if (checkedPayrollsAll) {
        if (
          company?.payrolls.filter((p) => p.state === 'created').length ===
          Object.keys(checkedPayrolls).length
        ) {
          return 'Approve company'
        }
        return 'Approve'
      }
      return 'Approve'
    }
    return 'Approve'
  }
  return (
    <>
      <tr key={company.company_id}>
        <td className="td_name">{company.company_name}</td>
        <td className="">{company.current_month}</td>
        <td className="">
          {company.total_count - company.count}/{company.total_count}
        </td>
        <td className="">
          {localCurrency} {totalAmount}
        </td>
        <td className="">
          {invoiceCurrency} {totalAmountInPartnerInvoicingCurrency}
        </td>
        <td className="">
          <Status status={company.status} />
        </td>
        <td style={{ width: '180px' }} className="">
          <Button
            priority="secondary"
            style={{ height: '32px' }}
            size="small"
            onClick={handleClick(company)}
            className="px-2 pl-0 mr-2"
          >
            {isOpen ? 'Hide' : 'Expand'}
          </Button>
          {isOpen && (
            <Button
              style={{ height: '32px' }}
              size="small"
              disabled={!checkedPayrolls}
              onClick={handleClickApprove}
              className="px-2 pl-0"
            >
              {handleCheckCountPayroll()}
            </Button>
          )}
        </td>
      </tr>
      <Body open={isOpen}>
        <MultiCurrencyExpanded
          setCheckedPayrollsAll={setCheckedPayrollsAll}
          checkedPayrolls={checkedPayrolls}
          setCheckedPayrolls={setCheckedPayrolls}
          localCurrency={localCurrency}
          invoiceCurrency={invoiceCurrency}
          localCurrencyCode={localCurrencyCode}
          invoiceCurrencyCode={invoiceCurrencyCode}
          payroll={company?.payrolls}
        />
      </Body>
    </>
  )
}

export const MultiCurrencyTable = ({ data, refetch, isLoading }) => {
  const [expandData, setExpandData] = useState(null)

  const { data: dataTotal, isLoading: isLoadingTotals } = useQuery(
    ['getPayrollsTotal'],
    {
      queryFn: () => getPayrollsTotal(),
    }
  )
  return (
    <Card>
      {isLoading ? (
        <div
          className="d-flex w-100 align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <Table>
          <thead>
            <tr>
              <th>Company</th>
              <th>Month</th>
              <th>Approved employees</th>
              <th>Local country currency</th>
              <th>Your invoice currency</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((company) => (
              <Item
                key={company.company_id}
                expandData={expandData}
                company={company}
                refetch={refetch}
                totalAmount={company.total_amount}
                totalAmountInPartnerInvoicingCurrency={
                  company.total_in_partner_invoicing_currency
                }
                localCurrencyCode={data?.partner_default_currency?.short_code}
                invoiceCurrencyCode={
                  data?.partner_invoicing_currency?.short_code
                }
                localCurrency={mapCurrency(data?.partner_default_currency)}
                invoiceCurrency={mapCurrency(data?.partner_invoicing_currency)}
                setExpandData={setExpandData}
              />
            ))}
          </tbody>
        </Table>
      )}
      {dataTotal && (
        <div className="payroll-bottom-wrapper">
          <div className="payroll-bottom d-flex justify-content-center">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Total of current payroll cycle:{' '}
                  {dataTotal?.total_payroll_amount}{' '}
                  {mapCurrency(dataTotal.company_currency)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.approved_payroll_companies_count} companies
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Already approved: {dataTotal?.approved_payroll_amount}{' '}
                  {mapCurrency(dataTotal.company_currency)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.approved_payroll_companies_count} companies
                </Typography>
              </div>
              <div className="d-flex flex-column">
                <Typography className="heading_semibold__20">
                  Pending: {dataTotal?.pending_payroll_amount}{' '}
                  {mapCurrency(dataTotal.company_currency)}
                </Typography>
                <Typography className="text_light__14 color_grey">
                  {dataTotal?.pending_payroll_companies_count} companies
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card>
  )
}

const Table = styled('table')`
  .actions {
    width: 20px;
  }
  .breakdown {
    width: 300px;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    width: 150px;
  }
  th {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #878787;
  }
  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .td_name {
    font-weight: 500;
  }
`

const Card = styled.div`
  border-radius: 8px;
  height: fit-content;
`

const Body = styled.tr`
  padding: 16px;
  background: #f9f9f9;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  display: ${(p) => (p.open ? 'table-row' : 'none')};
`
