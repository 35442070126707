import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import { Spinner } from '@atoms/Spinner/spinner'
import Typography from '@atoms/Typography/Typography'
import {
  fetchCreateAdditionPayment,
  fetchDeleteAdditionPayment,
  fetchUpdateAdditionPayment,
} from '@services/payments.service'
import React, { memo, useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import Accordion from './Accordion'
import CreateModal from './CreateModal'

const Styled = styled.div`
  margin: 8px 0;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: 8px 8px 0 0;
  padding: 16px;
  outline: none;
  background: #f9f9f9;
`
const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0 0 8px 8px;
`

const Item = memo(({ data, payments, isLoading, payrollId, refetch }) => {
  const { value, toggle } = useBoolean(false)
  const { value: modalValue, toggle: modalToggle } = useBoolean(false)
  const { value: modalPositiveValue, toggle: modalPositiveToggle } =
    useBoolean(false)
  const [paymentIdForDelete, setPaymentIdForDelete] = useState(null)

  const updateMutate = useMutation({
    mutationFn: ({ id, body }) => fetchUpdateAdditionPayment({ id, body }),
    onSuccess: () => {
      refetch()
    },
  })

  const deleteMutate = useMutation({
    mutationFn: (id) => fetchDeleteAdditionPayment(id),
    onSuccess: () => {
      refetch()
      setPaymentIdForDelete(null)
    },
  })

  const createMutate = useMutation({
    mutationFn: (body) => fetchCreateAdditionPayment(body),
    onSuccess: (res) => {
      refetch()
      if (res.state === 'MOVED_TO_NEXT_MONTH') modalToggle()
      if (res.state === 'APPROVED_BY_COMPANY') modalPositiveToggle()
      toggle()
    },
  })

  const handleSave = useCallback(
    ({ id, body }) => {
      updateMutate.mutate({
        id,
        body: { ...body, amount: Number(body.amount) },
      })
    },
    [updateMutate]
  )

  const handleDelete = useCallback(
    (id) => {
      deleteMutate.mutate(id)
    },
    [deleteMutate]
  )

  const handleCreate = useCallback(
    (body) => {
      createMutate.mutate({
        ...body,
        amount: Number(body.amount),
        payroll: Number(payrollId),
      })
    },
    [createMutate, payrollId]
  )

  return (
    <Styled>
      <Header
        style={
          payments.length
            ? { borderRadius: '8px 8px 0 0' }
            : { borderRadius: '8px' }
        }
      >
        <div className="d-flex w-100 justify-content-between align-items-center">
          <Typography className="heading_semibold__18">
            Additional Payments
          </Typography>
          <Button type="submit" size="small" onClick={toggle}>
            + Add additional payment
          </Button>
        </div>
      </Header>
      {payments.length > 0 && (
        <Body style={{ padding: '2rem' }}>
          <Spinner isFetching={isLoading}>
            <Accordion
              paymentData={data}
              items={payments}
              isLoading={updateMutate.isLoading}
              onDelete={(id) => setPaymentIdForDelete(id)}
              onSave={handleSave}
            />
          </Spinner>
        </Body>
      )}
      {value && (
        <CreateModal
          isLoading={createMutate.isLoading}
          onHide={toggle}
          data={data}
          onCreate={handleCreate}
          isSuccess={createMutate.isSuccess}
        />
      )}
      {!!paymentIdForDelete && (
        <Confirm
          title="Delete Payment"
          isLoading={deleteMutate.isLoading}
          onCancel={() => setPaymentIdForDelete(null)}
          onYes={() => handleDelete(paymentIdForDelete)}
        >
          <Typography>
            Are you sure you want to remove this payment from company invoice?
          </Typography>
        </Confirm>
      )}
      {modalValue && (
        <Confirm
          title=""
          cancelButtonProps={{
            text: 'Close',
          }}
          onCancel={modalToggle}
        >
          <Typography>
            This payment will be automatically added to the next payroll cycle
            as the cut-off date, day (5) of this month, has passed.
          </Typography>
        </Confirm>
      )}
      {modalPositiveValue && (
        <Confirm
          title=""
          cancelButtonProps={{
            text: 'Close',
          }}
          onCancel={modalPositiveToggle}
        >
          <Typography>
            This additional payment will be added to the current months payroll.
          </Typography>
        </Confirm>
      )}
    </Styled>
  )
})

const AdditionalPaymentsBlock = ({
  payments,
  isLoading,
  data,
  payrollId,
  refetch,
}) => {
  return (
    <Item
      payments={payments}
      isLoading={isLoading}
      data={data}
      payrollId={payrollId}
      refetch={refetch}
    />
  )
}

AdditionalPaymentsBlock.displayName = 'AdditionalPaymentsBlock'

export default memo(AdditionalPaymentsBlock)
