import { Api } from '@api/Api'
import { generateService } from '@core/utils'
import { CountryMinSalary } from '@remoteam-front/types/v2/country-salary.types-v2'

export const getMinSalaries = generateService(async (countryId: number) => {
  const { data } = await Api.get<Array<CountryMinSalary>>(
    `/countries/${countryId}/min-salaries-list/`
  )
  return data
})
