import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { ADDITIONAL_PAYMENT_TYPE_KEY } from '@pages/additional-payments/utils'
import { getCostCalculatorList } from '@services/cost-calculation.service'
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'

export const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'ALLOWANCE', label: 'Allowance' },
  { value: 'EXPENSES', label: 'Expenses' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

export const expenseTypes = [
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'COWORKING_SPACE', label: 'Coworking space' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'FOOD', label: 'Food' },
  { value: 'OFFICE_SUPPLIES', label: 'Office supplies' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'OTHER', label: 'Other' },
]

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`

const Form = ({ paymentData, data, isLoading, onDelete, onSave }) => {
  const { currencies, profile, userProfile } = useContext(AppContext)
  const [apTypeKeys, setApTypeKeys] = useState()

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ap_type: data.ap_type || '',
      amount: data.amount || 0,
      currency: data.currency.id || '',
      tax: data.tax,
    },
  })

  const addPmtListMutation = useQuery(
    [getCostCalculatorList.key, paymentData?.country?.id],
    {
      queryFn: () =>
        getCostCalculatorList.fetch({
          limit: 1000,
          offset: 0,
          country_id: paymentData?.country?.id,
          ignore_region: true,
          usage_area: 'additional_payment',
          partner: {
            id: profile.partner_profile_id,
            name: profile.name,
            user_id: userProfile.id,
          },
        }),
      enabled: !!paymentData?.country?.id,
      onSuccess: (res) => {
        setApTypeKeys(
          res?.results.reduce((acc, value) => {
            return { ...acc, [value.id]: value }
          }, {})
        )
      },
    }
  )

  useEffect(() => {
    if (data?.sub_ap_type && apTypeKeys) {
      const ap_type_id = Object.values(apTypeKeys).find(
        (value) => value.name === data?.sub_ap_type
      )?.id
      if (ap_type_id) setValue('ap_type_id', ap_type_id)
    }
  }, [data?.sub_ap_type, apTypeKeys])

  const submit = (formValues) => {
    const category =
      apTypeKeys &&
      apTypeKeys[formValues.ap_type_id?.value || formValues.ap_type_id]
    let body = {
      amount: formValues.amount,
      currency: formValues.currency.value,
      ap_type: category
        ? ADDITIONAL_PAYMENT_TYPE_KEY[category.additional_payment_category]
        : formValues.ap_type_id?.value || formValues.ap_type_id,
      sub_ap_type: category ? category.name : undefined,
    }
    if (formValues.tax) {
      body = { ...body, tax: formValues.tax }
    }
    onSave(body)
  }
  const options = useMemo(() => {
    return addPmtListMutation.data?.results.length
      ? addPmtListMutation.data.results.map((e) => ({
          value: e.id,
          label: e.name,
        }))
      : paymentTypes
  }, [addPmtListMutation.data])

  return (
    <StyledForm
      noValidate
      className="d-flex flex-column"
      onSubmit={handleSubmit(submit)}
    >
      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="ap_type_id"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="Form-0E7102"
                  {...field}
                  label="Payment type"
                  options={
                    data?.source === 'EXPENSE_MANAGEMENT'
                      ? expenseTypes
                      : options
                  }
                />
              )
            }}
          />
          {errors.ap_type_id && (
            <Typography className="text_regular__14 color_red">
              {errors.ap_type_id.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            rules={{
              required: 'asd is required',
            }}
            name="amount"
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label="Amount"
                placeholder="Enter the adjustment amount"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />
          {errors.amount && (
            <Typography className="text_regular__14 color_red">
              {errors.amount.message}
            </Typography>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="tax"
            rules={{ required: 'Field is required' }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label="Employer tax & contributions"
                placeholder="Enter the tax"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />
          {errors.tax && (
            <Typography className="text_regular__14 color_red">
              {errors.tax.message}
            </Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="currency"
            rules={{ required: 'Currency is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="Form-88B99B"
                  {...field}
                  label="Currency"
                  options={currencies.map((c) => ({
                    value: c.id,
                    label: c.name || c.short_code,
                  }))}
                />
              )
            }}
          />
          {errors.currency && (
            <Typography className="text_regular__14 color_red">
              {errors.currency.message}
            </Typography>
          )}
        </div>
      </div>
      <div className="align-self-end mb-0">
        <Button
          data-testid="Form-BF8089"
          className="mr-3 button__danger"
          size="small"
          type="button"
          onClick={onDelete}
        >
          {data.source === 'EXPENSE_MANAGEMENT' ? 'Cancel' : 'Delete'}
        </Button>
        <Button
          data-testid="Form-2D1518"
          size="small"
          type="submit"
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </StyledForm>
  )
}

Form.displayName = 'New Additional Pay Form'

export default memo(Form)
