import ModalSide from '@atoms/ModalSide/ModalSide'
import { capitalizeFirstLetter } from '@core/utils'
import AdditionalPaymentStatus from '@pages/additional-payments/additional-payments-status/AdditionalPaymentStatus'
import { getOccurrencesDisplay } from '@pages/additional-payments/utils'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled.table`
  width: 100%;
  border-right: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
  border-spacing: 0;
  border-collapse: separate;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
`

const TableRow = styled.tr`
  background-color: ${(props) => (props.$isEven ? '#FFFFFF' : '#F5F5F5')};
  height: 40px;
`

const TableCell = styled.td`
  padding: 4px 12px;
  color: #6d6d6c;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.$isLabel ? '700' : '500')};
  width: 50%;
  text-align: left;
  height: 40px;
`

const getFrequencyDisplay = (isRecurrent, frequency) => {
  if (!isRecurrent) return 'One-time'

  const frequencyMap = {
    MONTHLY: 'Monthly',
    EVERY_2_MONTHS: 'Every 2 months',
    EVERY_3_MONTHS: 'Every 3 months',
  }

  return frequencyMap[frequency] || 'One-time'
}

export default function ViewPaymentModal({ addPayment, onCloseClick }) {
  const rows = [
    {
      label: 'Created by',
      value: addPayment?.company_data.name,
    },
    {
      label: 'Created time',
      value: moment(addPayment?.created_at).format('DD MMM YYYY'),
    },
    {
      label: 'Payment type',
      value: capitalizeFirstLetter(addPayment?.get_ap_type_display),
    },
    {
      label: 'Amount',
      value: addPayment?.amount,
    },
    {
      label: 'Employer tax & contributions',
      value: addPayment?.tax || '0',
    },
    {
      label: 'Total',
      value: addPayment?.total_amount || '0',
    },
    {
      label: 'Expected payout',
      value: addPayment?.payout_date
        ? moment(addPayment.payout_date).format('MMMM YYYY')
        : '-',
    },
    {
      label: 'Frequency',
      value: getFrequencyDisplay(
        addPayment?.is_recurrent,
        addPayment?.recurrence_frequency
      ),
    },
    {
      label: 'Occurrences',
      value: getOccurrencesDisplay(addPayment),
    },
    {
      label: 'Title',
      value: addPayment?.name,
    },
    {
      label: 'Description',
      value: addPayment?.description,
    },
    {
      label: 'Status',
      value: <AdditionalPaymentStatus status={addPayment?.status} />,
    },
  ]

  return (
    <ModalSide
      title="View additional payment"
      subtitle={addPayment?.name}
      footer={false}
      onClose={onCloseClick}
    >
      <StyledTable>
        <tbody>
          {rows.map(({ label, value }, index) => (
            <TableRow key={label} $isEven={index % 2 === 1}>
              <TableCell $isLabel>{label}</TableCell>
              <TableCell>{value || '-'}</TableCell>
            </TableRow>
          ))}
        </tbody>
      </StyledTable>
    </ModalSide>
  )
}
