import BackButton from '@atoms/BackButton'
import { Spinner } from '@atoms/Spinner/spinner'
import Typography from '@atoms/Typography/Typography'
import AdditionalPaymentsBlock from '@pages/payrollsDetailEditPage/AdditionalPaymentsBlock'
import ExpensesBlock from '@pages/payrollsDetailEditPage/ExpensesBlock'
import InvoiceAccordion from '@pages/payrollsDetailEditPage/InvoiceAccordion'
import { getPayrollById, updatePayrollById } from '@services/payroll.service'
import { DotWave } from '@uiball/loaders'
import React, { useCallback } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'

export default function PayrollDetailEditPage() {
  const router = useRouteMatch()
  const { data, isLoading, refetch } = useQuery(
    ['payrolls', router.params.payrollId],
    {
      queryFn: () => getPayrollById(router.params.payrollId),
    }
  )

  const updatePayrollMutate = useMutation({
    mutationFn: ({ id, body }) => updatePayrollById(id, body),
    onSuccess: () => {
      refetch()
    },
  })

  const handleSaveInvoice = useCallback(
    ({ id, body }) => {
      updatePayrollMutate.mutate({
        id,
        body: { ...body, original_partner_fee: data.original_partner_fee },
      })
    },
    [updatePayrollMutate, data]
  )

  if (isLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  const expenseManagementPayments =
    data?.additional_payments?.filter(
      (payment) => payment.source === 'EXPENSE_MANAGEMENT'
    ) || []

  const additionalPayments =
    data?.additional_payments?.filter(
      (payment) => payment.source !== 'EXPENSE_MANAGEMENT'
    ) || []

  return (
    <div className="employee-detail-page h-100">
      {isLoading ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="d-flex align-items-center">
              <BackButton />
              <Typography className="heading_semibold__24 ml-3">
                Edit payroll
              </Typography>
            </div>
          </div>
          <div>
            <Spinner isFetching={isLoading}>
              <InvoiceAccordion
                isLoading={updatePayrollMutate.isLoading}
                payments={data}
                onSave={handleSaveInvoice}
              />
            </Spinner>
            <div style={{ marginTop: '24px' }} />
            <Spinner isFetching={isLoading}>
              <AdditionalPaymentsBlock
                payments={additionalPayments}
                isLoading={isLoading}
                data={data}
                payrollId={router.params.payrollId}
                refetch={refetch}
              />
            </Spinner>
            <div style={{ marginTop: '24px' }} />
            <Spinner isFetching={isLoading}>
              <ExpensesBlock
                expenses={expenseManagementPayments}
                isLoading={isLoading}
                payrollId={router.params.payrollId}
                refetch={refetch}
              />
            </Spinner>
          </div>
        </>
      )}
    </div>
  )
}
