import { AppContext, useApp } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  AsyncButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
} from '@remoteam-front/ui'
import { getContractById, updateCompensation } from '@services/app.service'
import {
  calculateAddPmtEmployerCostTaxes,
  getCostCalculatorList,
} from '@services/cost-calculation.service'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDebounce } from 'usehooks-ts'

import { recurrenceFrequency } from './consts'
import { StyledTextField } from './onboarding-tracker-required-compensation.styles'

const apTypeOptions = [
  {
    label: 'Bonus',
    value: 'BONUS',
  },
  {
    label: 'Allowance',
    value: 'ALLOWANCE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]

const TYPE_KEY = {
  BONUS: 'bonus',
  ALLOWANCE: 'allowance',
  OTHER: 'compensation',
}

export const CompensationUpdateDrawer = ({
  countryId,
  compensation,
  onClose,
}) => {
  const { currencies } = useApp()
  const queryClient = useQueryClient()
  const { successAlert } = useToast()
  const { profile, userProfile } = useContext(AppContext)

  const updateMutation = useMutation({
    mutationFn: (payload) => updateCompensation(compensation.id, payload),
    onSuccess: () => {
      onClose()
      queryClient.refetchQueries(getContractById.key)
      successAlert('Compensation successfully updated!')
    },
  })

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: compensation.name,
      ap_type: compensation.ap_type,
      currency: compensation.currency.id,
      is_contractual: compensation.is_contractual,
      is_indefinite: compensation.is_indefinite,
      isEndAfter: !compensation.is_indefinite,
      occurences: compensation.occurences,
      is_recurrent: compensation.is_recurrent,
      amount: compensation.amount,
      recurrence_frequency: compensation.recurrence_frequency || 'once',
      tax: compensation.tax,
    },
  })

  const compensationType = compensation.ap_type
  const frequency = watch('recurrence_frequency')
  const tax = watch('tax')
  const isEndAfter = watch('isEndAfter')
  const amount = watch('amount') || 0
  const subApType = watch('sub_ap_type')

  const debouncedAmount = useDebounce(amount)

  const [employerCosts, setEmployerCosts] = useState([])

  const [apValues, setApValues] = useState()

  const addPmtListMutation = useQuery([getCostCalculatorList.key], {
    queryFn: () =>
      getCostCalculatorList.fetch({
        usage_area: 'additional_payment',
        limit: 100,
        offset: 0,
        country_id: countryId,
        partner: {
          id: profile.partner_profile_id,
          name: profile.name,
          user_id: userProfile.id,
        },
      }),
    onSuccess: (res) => {
      const bonus = res?.results?.find(
        (ap) => ap.additional_payment_category === 'bonus'
      )
      const list = res?.results?.filter(
        (ap) => ap.additional_payment_category === TYPE_KEY[compensationType]
      )
      setValue(
        'sub_ap_type',
        compensationType === 'BONUS'
          ? bonus?.id
          : list.find((item) => item.name === compensation.sub_ap_type)?.id
      )

      setApValues(
        res?.results?.reduce(
          (acc, item) => {
            const result = { ...acc, [item.id]: item.name }
            return result
          },
          [{}]
        )
      )
      setEmployerCosts(compensationType === 'BONUS' ? [] : list)
    },
  })

  useQuery(
    [
      calculateAddPmtEmployerCostTaxes.key,
      debouncedAmount,
      compensationType,
      countryId,
      subApType,
    ],
    {
      enabled: !!subApType && !!countryId,
      queryFn: () =>
        calculateAddPmtEmployerCostTaxes.fetch({
          additional_payment_amount: debouncedAmount,
          additional_payment_type_id: subApType,
          country: countryId,
        }),
      // onSuccess: (res) => {
      //   setValue('tax', res?.tax_amount)
      // },
    }
  )

  const onSubmit = (formData) => {
    const submitData = { ...formData }
    if (
      formData.recurrence_frequency &&
      formData.recurrence_frequency !== 'once'
    ) {
      submitData.is_recurrent = true
    }
    if (formData.recurrence_frequency === 'once') {
      submitData.is_indefinite = false
      delete submitData.recurrence_frequency
    }

    if (!formData.isEndAfter) {
      submitData.occurences = undefined
    }
    submitData.is_indefinite = !formData.isEndAfter
    delete submitData.isEndAfter

    submitData.sub_ap_type =
      (apValues && apValues[submitData.sub_ap_type]) || undefined

    updateMutation.mutate(submitData)
  }

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <DrawerHeader title="Edit compensation" onClose={onClose} />
        <DrawerBody>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Title"
              fullWidth
              required
              error={!!errors.name}
              helperText={
                errors.name?.message || 'This term will be used in the payroll'
              }
              {...register('name', {
                required: 'Field is required',
                maxLength: {
                  value: 50,
                  message: 'Title cannot exceed 50 characters',
                },
              })}
            />
            <Controller
              control={control}
              name="ap_type"
              render={({ field: { ...field } }) => (
                <FormControl error={!!errors.ap_type} required fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    disabled
                    error={!!errors.ap_type}
                    helperText={errors.ap_type?.message}
                    {...field}
                  >
                    {apTypeOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {(employerCosts?.length && (
              <div className="remo-form-input">
                <Controller
                  control={control}
                  name="sub_ap_type"
                  rules={{ required: 'Type is required' }}
                  render={({ field }) => {
                    return (
                      <FormControl
                        error={!!errors.sub_ap_type}
                        required
                        fullWidth
                      >
                        <InputLabel>
                          {compensationType === 'OTHER'
                            ? 'Compensation'
                            : 'Allowance'}{' '}
                          type
                        </InputLabel>
                        <Select
                          {...field}
                          error={!!errors.sub_ap_type}
                          helperText={errors.sub_ap_type?.message}
                        >
                          {employerCosts.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )
                  }}
                />
                {errors.type && (
                  <Typography className="text_regular__14 color_red">
                    {errors.type.message}
                  </Typography>
                )}
              </div>
            )) ||
              ''}
            <Controller
              control={control}
              name="currency"
              render={({ field: { ref, ...field } }) => (
                <FormControl error={!!errors.currency} required fullWidth>
                  <InputLabel id="select-label">Currency</InputLabel>
                  <Select
                    inputRef={ref}
                    error={!!errors.currency}
                    helperText={errors.currency?.message}
                    {...field}
                  >
                    {currencies.map(({ id, name, short_code }) => (
                      <MenuItem key={id} value={id}>
                        {name || short_code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <div>
              <TextField
                label="Amount"
                fullWidth
                required
                type="number"
                error={!!errors.amount}
                helperText={errors.amount?.message}
                {...register('amount', {
                  min: {
                    value: 1,
                    message: 'Amount cannot be less than 0',
                  },
                })}
                inputProps={{
                  step: '0.01',
                }}
              />
            </div>

            <TextField
              label="Employer tax & contributions"
              fullWidth
              required
              type="number"
              placeholder="Type exact amount"
              error={!!errors.tax}
              helperText={errors.tax?.message}
              {...register('tax', {
                min: {
                  value: 0,
                  message: 'Tax cannot be negative',
                },
              })}
              inputProps={{
                step: '0.01',
              }}
            />

            <TextField
              label="Total"
              fullWidth
              required
              type="number"
              disabled
              value={+amount + (+tax || 0)}
              placeholder="Total amount"
              helperText="The estimated total amount, including employer taxes and contributions"
            />

            {compensationType !== 'BONUS' && (
              <>
                <Controller
                  control={control}
                  name="recurrence_frequency"
                  render={({ field: { ref, ...field } }) => (
                    <FormControl
                      error={!!errors.recurrence_frequency}
                      required
                      fullWidth
                    >
                      <InputLabel id="select-label">Frequency</InputLabel>
                      <Select
                        inputRef={ref}
                        error={!!errors.recurrence_frequency}
                        helperText={errors.recurrence_frequency?.message}
                        {...field}
                      >
                        <MenuItem key="once" value="once">
                          One-time
                        </MenuItem>
                        {recurrenceFrequency.map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {frequency !== 'once' && (
                  <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    sx={{ my: 2 }}
                  >
                    <Controller
                      name="isEndAfter"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label={
                            <Typography variant="body2">End after</Typography>
                          }
                        />
                      )}
                    />
                    <Box display="flex" gap={1} alignItems="center">
                      <StyledTextField
                        required
                        type="number"
                        disabled={!isEndAfter}
                        error={!!errors.occurences}
                        helperText={errors.occurences?.message}
                        {...register('occurences', {
                          min: {
                            value: 0,
                            message: 'Amount cannot be negative',
                          },
                        })}
                        onInput={(e) => {
                          e.target.value = Math.max(
                            0,
                            parseFloat(e.target.value)
                          ).toString()
                        }}
                      />

                      <Typography>occurrences</Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}

            <Controller
              name="is_contractual"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={
                    <Typography variant="body2">
                      Include this allowance in the EoR contract
                    </Typography>
                  }
                />
              )}
            />
          </Box>
        </DrawerBody>
        <Box
          display="flex"
          justifyContent="end"
          flexDirection="column"
          height="40%"
        >
          <DrawerFooter
            primaryActions={[
              <AsyncButton
                data-testid="compensation-update-drawer.component-1E483B"
                type="submit"
                isFetching={updateMutation.isLoading}
                variant="contained"
                color="primary"
                size="medium"
                key={1}
              >
                Save
              </AsyncButton>,
            ]}
            secondaryActions={[
              <Button
                data-testid="compensation-update-drawer.component-E6B42B"
                variant="contained"
                color="secondary"
                size="medium"
                key={2}
                onClick={onClose}
              >
                Cancel
              </Button>,
            ]}
          />
        </Box>
      </form>
    </Drawer>
  )
}
