import './style.scss'

import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { AttachmentUpload } from '@components/attachment/attachment-upload.component'
import { PAYROLL_STATUSES } from '@core/constants'
import { useSearchParams } from '@core/hooks/useRouteQuery'
import { setCurrencyChangeEvent } from '@core/utils'
import ChangesAccordion from '@pages/payrollsDetailPage/ChangesAccordion'
import { getCompaniesShort } from '@services/company.service'
import { customFxRateCreate } from '@services/custom-fx-rate.service'
import {
  approveFlowPayroll,
  getPayrollListCompany,
  getPayrollListNeedChange,
} from '@services/payroll.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { MultiCurrencyTable } from './multi-currency-table/multi-currency-table.component'

const FilterStates = [
  PAYROLL_STATUSES.AWAITING_PAYMENT,
  PAYROLL_STATUSES.CREATED,
  PAYROLL_STATUSES.PAID,
  PAYROLL_STATUSES.CANCELED,
]

export default function MultiCurrencyPage() {
  const { params, setParams } = useSearchParams()
  const [attachments, setAttachments] = useState([])
  const [attachment, setAttachment] = useState(document?.attachment)
  const [loadingAttachment, setLoadingAttachment] = useState(false)
  const [status, setStatus] = useState(params.get('status') || '')
  const [customFxRate, setCustomFxRate] = useState()
  const [errorText, setErrorText] = useState()
  const [search, setSearch] = useState(params.get('search') || '')
  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)

  const approvePayrollRequest = useMutation('approveFlowPayroll', {
    mutationFn: (payload) => approveFlowPayroll(payload),
    onSuccess: () => {
      toast.success('Successfully updated')
      refetch()
    },
  })
  const { data: companiesData } = useQuery(['CompaniesShort'], {
    queryFn: () => getCompaniesShort({ limit: 1000 }),
  })

  const customFxRateCreateMutation = useMutation({
    mutationFn: (payload) => customFxRateCreate(payload),
    onSuccess: () => {
      toggleDelete()
      refetch()
      toast.success('FX rate updated successfully')
    },
  })

  const {
    data: companyPayrollList,
    refetch,
    isFetching,
  } = useQuery(['getPayrollListCompany'], {
    queryFn: () => getPayrollListCompany({ state: 'pending' }),
  })

  const { data: listNeedChange, isLoading: changeLoading } = useQuery(
    'payrollsListNeedChange',
    {
      queryFn: () => getPayrollListNeedChange(),
    }
  )

  const handleCompanyChange = (option) => {
    const val = option?.value || null
    setSearch(val)
    setParams({ search: val || '', status })
  }
  const handleApproveAll = () => {
    approvePayrollRequest.mutate({ for_all: true })
  }
  const onSendFxRate = () => {
    if (attachment.file_uuid) {
      customFxRateCreateMutation.mutate({
        attachment_document: attachment.file_uuid,
        custom_fx_rate: customFxRate,
      })
    } else {
      setErrorText('File is required!')
    }
  }

  const handleAttach = (files) => {
    setErrorText(undefined)
    setAttachments(files)
  }
  const handleChangeFxRate = (e) => {
    setCustomFxRate(e.target.value)
  }

  const handleDelete = (fileId) => {
    setAttachments((prev) => prev.filter((id) => id !== fileId))
  }

  const fxRate =
    (companyPayrollList?.data &&
      companyPayrollList?.data[0] &&
      companyPayrollList?.data[0].payrolls[0].partner_custom_fx_rate
        .custom_fx_rate) ||
    (companyPayrollList?.data &&
      companyPayrollList?.data[0] &&
      companyPayrollList?.data[0].payrolls[0].remofirst_fx_rate) ||
    0

  return (
    <div className="customer-payroll">
      <div className="d-flex align-items-center mb-4">
        <Typography className="heading_semibold__24">Payroll</Typography>
      </div>
      <Tabs>
        <Tab tabId={0} title="Pending">
          <div>
            <div className="d-flex mb-4 align-items-center gap-3 justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div className="d-flex align-items-center gap-3">
                  <div style={{ width: 250 }}>
                    <Select
                      placeholder="Select company"
                      value={search}
                      onChange={handleCompanyChange}
                      options={companiesData?.results?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: 190 }}>
                <Button
                  style={{ height: '32px' }}
                  size="small"
                  className="px-2 pl-0"
                  onClick={handleApproveAll}
                >
                  Approve All
                </Button>
              </div>
            </div>
            <div style={{ width: 'fit-content' }}>
              {!isFetching && companyPayrollList && (
                <NotificationBlock
                  variant="blue"
                  render={
                    <Typography className="text_regular__14 ml-2">
                      The FX rate used is 1{' '}
                      {companyPayrollList?.partner_default_currency
                        ?.short_code ||
                        companyPayrollList?.partner_default_currency?.sign}{' '}
                      = {fxRate}{' '}
                      {companyPayrollList?.partner_invoicing_currency
                        ?.short_code ||
                        companyPayrollList?.partner_invoicing_currency
                          ?.sign}{' '}
                      .{' '}
                      <Button
                        style={{
                          maxWidth: 'fit-content',
                          height: 22,
                          fontWeight: 700,
                          color: '#4C92C7',
                          textDecoration: 'underline',
                        }}
                        className="p-0"
                        priority="outlined"
                        size="small"
                        onClick={toggleDelete}
                      >
                        Edit
                      </Button>
                    </Typography>
                  }
                />
              )}
            </div>
            <MultiCurrencyTable
              data={companyPayrollList}
              isLoading={isFetching}
              refetch={refetch}
            />
          </div>
        </Tab>
        <Tab tabId={1} title="Requested Change" count={listNeedChange?.count}>
          {changeLoading && listNeedChange?.length === 0 ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div>
              <ChangesAccordion
                month={listNeedChange?.month}
                payments={listNeedChange?.payrolls}
              />
            </div>
          )}
        </Tab>
      </Tabs>
      <EditModal
        visible={visibleDelete}
        title="FX rate"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <div className="mb-2">
          <CurrencyInput
            className="mb-2"
            isRequired
            decimalScale={9}
            value={fxRate}
            label="RemoFirst rate"
            disabled
          />
        </div>
        <div className="mb-2">
          <CurrencyInput
            isRequired
            decimalScale={9}
            label="Your suggested rate"
            placeholder="Enter the adjustment amount"
            onChange={setCurrencyChangeEvent(handleChangeFxRate)}
          />
        </div>
        <AttachmentUpload
          accept={{
            'image/*': ['.png', '.jpg', '.jpeg'],
            'application/pdf': ['.pdf'],
          }}
          customErrorText={errorText}
          isRequired
          onAttach={(fileIds) => handleAttach(fileIds)}
          onDelete={(fileId) => handleDelete(fileId)}
          setAttachment={setAttachment}
          setLoadingAttachment={setLoadingAttachment}
        />
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="PayrollDetailPage-FBE06F"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            data-testid="PayrollDetailPage-40DD71"
            priority="primary"
            size="small"
            loading={customFxRateCreateMutation.isLoading || loadingAttachment}
            onClick={onSendFxRate}
          >
            Save
          </Button>
        </div>
      </EditModal>
    </div>
  )
}
