import { Api, ApiCostCalculator } from '@api/Api'
import { generateService } from '@core/utils'

export const fetchGetCostCalculationTax = async (payload) => {
  const { data } = await Api.post(`employer-cost/calculate-taxes/`, payload)
  return data
}

export const getCostCalculatorList = generateService(async (payload) => {
  const { data } = await ApiCostCalculator.post(
    `employer-cost/tax-types/`,
    payload
  )
  return data
})

export const costCalculateDownloadPdf = async (payload) => {
  const { data } = await Api.post(
    'employer-cost/calculate-taxes/generate-pdf/',
    payload,
    {
      responseType: 'blob',
    }
  )

  return data
}

export const calculateAddPmtEmployerCostTaxes = generateService(
  async (params) => {
    const { data } = await Api.get(`additional-payments/tax-calculation/`, {
      params,
    })
    return data
  }
)
