import { format, isValid, parse } from 'date-fns'

export const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()

export const mapEnum = (value: string) => value.split('_').join(' ')

export const splitAmount = (num: number) =>
  String(Number(Math.round(num * 100) / 100).toFixed(2)).replace(
    /(\d)(?=(\d{3})+(\D|$))/g,
    '$1 '
  )

export const splitNumber = (num: number) =>
  String(Number(num).toFixed(2)).replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')

export const isValidDate = (date: any) =>
  date === null ? false : !Number.isNaN(new Date(date).getTime())

export const validateMUIDateFormat = (date: any, dateFormat: string) => {
  if (!isValidDate(date)) return false

  const formattedDate = format(new Date(date), dateFormat)
  const parsedDate = parse(formattedDate, dateFormat, new Date())

  return isValid(parsedDate)
}

export const getUTCDate = (dateString: string) => {
  const date = new Date(dateString)
  return isValidDate(date)
    ? new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    : date
}

export const formatDate = (date: any) =>
  isValidDate(date) ? format(getUTCDate(date), 'dd MMM y') : date

export const formatSendDate = (date: any) =>
  isValidDate(date) ? format(getUTCDate(date), 'yyyy-MM-dd') : date

export const formatSendDateNonUTC = (date: any) =>
  isValidDate(date) ? format(new Date(date), 'yyyy-MM-dd') : date

export const formatDateTime = (date: any) =>
  isValidDate(date) && format(new Date(date), 'dd MMM y HH:mm')

/**
 *
 * @param allParams old params
 * @param key
 * @param value
 * @returns new search params [with new link to object];
 */
export const getNewParams = (
  allParams: URLSearchParams,
  key: string,
  value: string
): URLSearchParams => {
  const newParams = new URLSearchParams(allParams)

  // if value is defined set into address bar else remove
  if (value) {
    newParams.set(key, value)
    return newParams
  }

  newParams.delete(key)

  return newParams
}

export const clearKompassifyInfo = () => {
  localStorage.removeItem('KOMPASSIFY_USER_REGISTRATION_DATE')
  localStorage.removeItem('kompassifyUserSegments')
  localStorage.removeItem('kompassifyUserId')
  localStorage.removeItem('KompassifyTemplateMap')
}

export const isLastIndex = <T>(v: Array<T>, index: number): boolean => {
  const { length } = v
  return length - 1 === index
}
