import { numberWithSpaces } from '@core/utils'
import { mapCurrency } from '@remoteam-front/utils/currency'
import { DotWave } from '@uiball/loaders'
import React from 'react'

import PayrollDetails from './PayrollDetails'

export const parserPayrollsBasePayment = (payrolls, invoiceTotal, loading) => {
  return payrolls.map((payroll) => ({
    id: payroll.id,
    gross_salary: `${mapCurrency(payroll?.partner_currency)} ${numberWithSpaces(
      payroll?.total_in_partner_currency
    )}`,
    name: payroll.user.full_name,
    avatar: payroll.user.avatar,
    status: payroll.state,
    ...payroll,
    country: payroll?.country?.name,
    disabled:
      payroll.state !== 'created'
        ? payroll.state !== 'need_changes_from_partner'
        : false,
    collapseContent: loading ? (
      <div
        className="d-flex w-100 align-items-center justify-content-center"
        style={{ height: 200 }}
      >
        <DotWave size={32} speed={1} color="black" />
      </div>
    ) : (
      <PayrollDetails payroll={payroll} invoiceTotal={invoiceTotal} />
    ),
  }))
}
