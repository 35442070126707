import './style.scss'

import { Api } from '@api/Api'
import BackButton from '@atoms/BackButton'
import Button from '@atoms/Button/Button'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { getContractById } from '@services/app.service'
import {
  getContractXLSFile,
  getSubStateChangeRequest,
} from '@services/contract.service'
import {
  fetchGetProvinces,
  fetchOccupations,
} from '@services/countries.service'
import { DotWave } from '@uiball/loaders'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'

import { Notes } from '../../features/notes/notes.component'
import { EmployeeBankAccount } from './employee-bank-account/employee-bank-account.component'
import { EmployeeOffboardingTracker } from './employee-offboarding-tracker/employee-offboarding-tracker.component'
import Agreements from './libs/Agreements/agreements'
import Benefit from './libs/Benefit/Benefit'
import DocumentDetailEmployee from './libs/DocumentDetailEmployee/DocumentDetailEmployee'
import EmergencyContact from './libs/EmergencyContact/EmergencyContact'
import JobDetails from './libs/JobDetails/JobDetails'
import Payslips from './libs/Payslips/Payslips'
import Personal from './libs/Personal/Personal'
import TimeOffEmployee from './libs/TimeOff/TimeOffEmployee'
import { OnboardingTrackerTab } from './onboarding-tracker-tab/onboarding-tracker-tab.component'

const tabIds = {
  onboardingTracker: 0,
  personal: 1,
  jobDetails: 2,
  bankAccount: 3,
  benefits: 4,
  emergencyContact: 5,
  payslips: 6,
  documents: 7,
  agreements: 8,
  timeOff: 9,
  notes: 10,
  offboardingTracker: 100,
}

export default function EmployeeDetailPage() {
  const router = useRouteMatch()

  const [employee, setEmployee] = useState()
  const [downloadClicked, setDownloadClicked] = useState(false)
  const [occupations, setOccupations] = useState([])
  const [regions, setRegions] = useState([])
  const [activeTab, setActiveTab] = useState(
    router.params.tabId ? +router.params.tabId : 1
  )

  const employeeQuery = useQuery([getContractById.key], {
    enabled: !!router.params.id,
    queryFn: () => getContractById.fetch(router.params.id),
    onSuccess: (response) => {
      setEmployee(response)
    },
  })

  const onboardingData = useQuery(
    'fetchOccupations',
    () => fetchOccupations(employee.working_country.id),
    {
      enabled: !!employee?.working_country?.id,
      onSuccess: (data) => {
        if (data.results) {
          setOccupations(data.results)
        }
      },
    }
  )
  const regionsData = useQuery(
    'fetchRegions',
    () => fetchGetProvinces(employee.working_country.id),
    {
      enabled: !!employee?.working_country?.id,
      onSuccess: (data) => {
        if (data.results) {
          setRegions(data.results)
        }
      },
    }
  )

  useQuery(['getContractXLSFile', employee, downloadClicked], {
    enabled: !!employee && downloadClicked,
    queryFn: () => getContractXLSFile(employee.id),
    onSuccess: (response) => {
      setDownloadClicked(false)
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        `${employee?.profile.first_name || ''} ${
          employee?.profile.last_name || ''
        }.xls`
      )
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    },
    onError: () => setDownloadClicked(false),
  })

  const getSubStateChangeRequestQuery = useQuery(
    getSubStateChangeRequest.key,
    () =>
      getSubStateChangeRequest.fetch(
        router.params.id,
        {
          request_type: 'offboarding',
        },
        {
          enabled: !!router.params.id && employee?.sub_state === 'offboarding',
        }
      )
  )

  const onClickDownloadXls = () => {
    setDownloadClicked(true)
  }

  useEffect(() => {
    if (employee?.state === 'onboarding') {
      setActiveTab(tabIds.onboardingTracker)
    } else if (
      getSubStateChangeRequestQuery.isSuccess &&
      employee?.contract_type === 'full_time_employee'
    ) {
      setActiveTab(tabIds.offboardingTracker)
    } else {
      setActiveTab(tabIds.personal)
    }
  }, [
    employee?.contract_type,
    employee?.state,
    getSubStateChangeRequestQuery.isSuccess,
  ])

  return (
    <div className="employee-detail-page h-100">
      {employeeQuery.isLoading || getSubStateChangeRequestQuery.isLoading ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="d-flex align-items-center">
              <BackButton path="/pages/employees" />
              <Typography className="heading_semibold__24 ml-3">
                {employee?.profile.first_name} {employee?.profile.last_name}
              </Typography>
            </div>
            <Button
              data-testid="EmployeeDetailPage-DA4AF3"
              priority="secondary"
              size="small"
              className="mt-3"
              onClick={onClickDownloadXls}
            >
              Download report
            </Button>
          </div>
          <Tabs selectedTab={activeTab} onTabChange={setActiveTab}>
            {getSubStateChangeRequestQuery.isSuccess &&
              employee?.contract_type === 'full_time_employee' && (
                <Tab
                  tabId={tabIds.offboardingTracker}
                  title="Off-boarding tracker"
                >
                  {employee && (
                    <EmployeeOffboardingTracker
                      data={getSubStateChangeRequestQuery.data}
                      employee={employee}
                    />
                  )}
                </Tab>
              )}
            {employee?.state === 'onboarding' && (
              <Tab tabId={tabIds.onboardingTracker} title="Onboarding tracker">
                {employee && <OnboardingTrackerTab employee={employee} />}
              </Tab>
            )}
            <Tab tabId={tabIds.personal} title="Personal">
              {employee && (
                <Personal
                  employee={employee}
                  setEmployee={setEmployee}
                  refetch={employeeQuery.refetch}
                />
              )}
            </Tab>
            <Tab tabId={tabIds.jobDetails} title="Job details">
              {employee && (
                <JobDetails
                  employee={employee}
                  setEmployee={setEmployee}
                  occupations={occupations}
                  regions={regions}
                />
              )}
            </Tab>
            <Tab tabId={tabIds.bankAccount} title="Bank account">
              {employee && <EmployeeBankAccount employee={employee} />}
            </Tab>
            <Tab tabId={tabIds.benefits} title="Benefits">
              {employee && (
                <Benefit employee={employee} setEmployee={setEmployee} />
              )}
            </Tab>
            <Tab
              tabId={tabIds.emergencyContact}
              title="Emergency contact"
              hidden={
                employee &&
                (employee?.contract_type === 'contractor' ||
                  employee.state !== 'active')
              }
            >
              {employee && <EmergencyContact employee={employee} />}
            </Tab>
            <Tab tabId={tabIds.payslips} title="Payslips">
              {employee && <Payslips employee={employee} />}
            </Tab>
            <Tab tabId={tabIds.documents} title="Documents">
              {employee && <DocumentDetailEmployee id={employee.id} />}
            </Tab>
            <Tab tabId={tabIds.agreements} title="Agreements">
              {employee && <Agreements employee={employee} />}
            </Tab>
            <Tab tabId={tabIds.timeOff} title="Time-off">
              {employee && (
                <TimeOffEmployee
                  employee={employee}
                  refetchEmployee={employeeQuery.refetch}
                />
              )}
            </Tab>
            <Tab tabId={tabIds.notes} title="Notes">
              {employee && <Notes contractId={employee.id} />}
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  )
}
