import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { EmploymentType, NoticePeriod } from '@remoteam-front/types/v2'

type Props = {
  data: NoticePeriod[]
}

const getEmploymentType = (type: EmploymentType): string => {
  if (type === EmploymentType.FullTime) {
    return 'Full-time'
  }

  if (type === EmploymentType.PartTime) {
    return 'Part-time'
  }

  return '-'
}

export const NoticePeriodTable = ({ data }: Props) => {
  return (
    <Table
      data-testid="notice-period.component-47114C"
      sx={{ border: 'unset' }}
    >
      <TableHead>
        <TableRow sx={{ height: 40 }}>
          <TableCell colSpan={2} sx={{ background: '#fff', border: 'unset' }} />
          <TableCell align="center" colSpan={3} sx={{ background: '#FFA530' }}>
            During probation
          </TableCell>
          <TableCell align="center" colSpan={3} sx={{ background: '#2DB77B' }}>
            Post probation
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            sx={{
              borderLeft: 'solid 1px #eee !important',
              borderTop: 'solid 1px #eee !important',
            }}
          >
            Contract type
          </TableCell>
          <TableCell sx={{ borderTop: 'solid 1px #eee !important' }}>
            Employment type
          </TableCell>
          <TableCell sx={{ background: '#FFECE5' }}>Minimum</TableCell>
          <TableCell sx={{ background: '#FFECE5' }}>Maximum</TableCell>
          <TableCell sx={{ background: '#FFECE5' }}>Recommended</TableCell>
          <TableCell sx={{ background: '#EBFCEF' }}>Minimum</TableCell>
          <TableCell sx={{ background: '#EBFCEF' }}>Maximum</TableCell>
          <TableCell sx={{ background: '#EBFCEF' }}>Recommended</TableCell>
          <TableCell
            sx={{
              borderRight: 'solid 1px #eee !important',
            }}
          >
            Note
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {data.map(
          ({
            id,
            contract_duration,
            contract_type,
            minimum_notice_period_during_probation,
            maximum_notice_period_during_probation,
            suggested_notice_period_during_probation,
            minimum_notice_period_after_probation,
            maximum_notice_period_after_probation,
            suggested_notice_period_after_probation,
            note,
          }) => (
            <TableRow key={id}>
              <TableCell sx={{ borderLeft: 'solid 1px #eee !important' }}>
                {contract_duration === 'fixed' ? 'Fixed' : 'Permanent'}
              </TableCell>
              <TableCell>{getEmploymentType(contract_type)}</TableCell>
              <TableCell>
                {minimum_notice_period_during_probation
                  ? `${minimum_notice_period_during_probation} days`
                  : '-'}
              </TableCell>
              <TableCell>
                {maximum_notice_period_during_probation
                  ? `${maximum_notice_period_during_probation} days`
                  : '-'}
              </TableCell>
              <TableCell>
                {suggested_notice_period_during_probation
                  ? `${suggested_notice_period_during_probation} days`
                  : '-'}
              </TableCell>
              <TableCell>
                {minimum_notice_period_after_probation
                  ? `${minimum_notice_period_after_probation} days`
                  : '-'}
              </TableCell>
              <TableCell>
                {maximum_notice_period_after_probation
                  ? `${maximum_notice_period_after_probation} days`
                  : '-'}
              </TableCell>
              <TableCell>
                {suggested_notice_period_after_probation
                  ? `${suggested_notice_period_after_probation} days`
                  : '-'}
              </TableCell>
              <TableCell sx={{ borderRight: 'solid 1px #eee !important' }}>
                {note || '-'}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  )
}
