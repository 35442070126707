import { capitalizeFirstLetter, mapEnum, SplitNumber } from '@core/utils'
import moment from 'moment'

export const parseStatus = (status) => {
  if (
    status === 'CREATED' ||
    status === 'MOVED_TO_NEXT_MONTH' ||
    status === 'PENDING'
  ) {
    return 'created'
  }
  if (status === 'APPROVED_BY_COMPANY' || status === 'AWAITING_PAYMENT') {
    return 'AWAITING_PAYMENT'
  }
  if (status === 'PAID') {
    return 'PAID'
  }
  if (
    status === 'REJECTED' ||
    status === 'CANCELLED' ||
    status === 'CANCELED' ||
    status === 'DELETED'
  ) {
    return 'Cancelled'
  }
  if (status === 'INVOICED') return 'Invoiced'
  return status
}

export const parserPayrollsBasePayment = (payments) => {
  return payments.map((payment) => ({
    ...payment,
    id: payment.id,
    name: payment?.contract_data?.full_name,
    title: payment?.name,
    status: parseStatus(payment.state),
    created_at: moment(payment.created_at).format('DD MMM YYYY'),
    company: payment.company_data.name,
    recurrence_frequency_label: mapEnum(
      capitalizeFirstLetter(payment.recurrence_frequency)
    ),
    amount: `${SplitNumber(payment.amount)}`,
    total_amount: `${SplitNumber(payment.amount + payment.tax)} ${
      payment?.currency?.sign
    }`,
    total_amount_in_partner: `${SplitNumber(
      payment.amount_in_partner_currency + payment.tax_in_partner_currency
    )} ${payment?.partner_currency?.sign}`,
  }))
}
