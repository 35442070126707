import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import RadioButtonHorizontal from '@atoms/RadioButtonHorizontal/RadioButtonHorizontal'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import {
  calculateAddPmtEmployerCostTaxes,
  getCostCalculatorList,
} from '@services/cost-calculation.service'
import { postCalculateLastDay } from '@services/payroll.service'
import { isNumber } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useDebounce } from 'usehooks-ts'

import {
  ADDITIONAL_PAYMENT_TYPE_KEY,
  monthOptions,
} from '../additional-payments/utils'

const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'ALLOWANCE', label: 'Allowance' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

const recurrenceFrequency = [
  { value: 'MONTHLY', label: 'Monthly recurring' },
  { value: 'EVERY_SECOND_MONTH', label: 'Every 2 months recurring' },
  { value: 'QUARTERLY', label: 'Quarterly recurring' },
]

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`
const formId = 'payment_create_form'

export default ({ data, isLoading, onHide, onCreate, isSuccess }) => {
  const { currencies, profile, userProfile } = useApp()
  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const [minDate, setMinDate] = useState(currentDate)
  const [apTypeKeys, setApTypeKeys] = useState()

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({})

  const isRecurrentWatch = watch('is_recurrent')
  const starts_at_watch = watch('recurrence_starting_month')
  const type = watch('ap_type_id')
  const amount = watch('amount')
  const tax = watch('tax')
  const total = +(amount || 0) + +(tax || 0)
  const debounceAmount = useDebounce(amount)

  const addPmtListMutation = useQuery(
    [getCostCalculatorList.key, data?.country?.id],
    {
      queryFn: () =>
        getCostCalculatorList.fetch({
          limit: 1000,
          offset: 0,
          country_id: data?.country?.id,
          ignore_region: true,
          usage_area: 'additional_payment',
          partner: {
            id: profile.partner_profile_id,
            name: profile.name,
            user_id: userProfile.id,
          },
        }),
      enabled: !!data?.country?.id,
      onSuccess: (res) => {
        setApTypeKeys(
          res?.results.reduce((acc, value) => {
            return { ...acc, [value.id]: value }
          }, {})
        )
      },
    }
  )

  useQuery(
    [
      calculateAddPmtEmployerCostTaxes.key,
      debounceAmount,
      data?.country?.id,
      type,
    ],
    {
      enabled: isNumber(type?.value || type) && !!data?.country?.id,
      queryFn: () =>
        calculateAddPmtEmployerCostTaxes.fetch({
          additional_payment_amount: debounceAmount || 0,
          additional_payment_type_id: type?.value || type,
          country: data?.country?.id,
        }),
      onSuccess: (res) => {
        setValue('tax', res.tax_amount)
      },
    }
  )

  const postCalculateLastDayMutate = useMutation('postCalculateLastDay', {
    mutationFn: (payload) => postCalculateLastDay(payload),
  })

  const submit = (formValues) => {
    const category =
      apTypeKeys &&
      apTypeKeys[formValues.ap_type_id?.value || formValues.ap_type_id]
    let body = {
      amount: formValues.amount,
      description: formValues.description,
      name: formValues.name,
      currency: formValues.currency.value || formValues.currency,
      is_recurrent: formValues.is_recurrent,
      ap_type: category
        ? ADDITIONAL_PAYMENT_TYPE_KEY[category.additional_payment_category]
        : formValues.ap_type_id?.value || formValues.ap_type_id,
      sub_ap_type: category ? category.name : undefined,
    }
    if (isRecurrentWatch === 'true') {
      body = {
        ...body,
        recurrence_frequency: formValues.recurrence_frequency.value,
        recurrence_starting_month: formValues.recurrence_starting_month
          ? formValues.recurrence_starting_month.value
          : '',
        recurrence_ending_month: formValues.recurrence_ending_month
          ? formValues.recurrence_ending_month.value
          : '',
      }
    }
    if (formValues.tax) {
      body = {
        ...body,
        tax: formValues.tax,
      }
    }
    onCreate(body)
  }

  useEffect(() => {
    if (isSuccess) {
      reset()
    }
  }, [isSuccess])

  useEffect(() => {
    if (profile) {
      postCalculateLastDayMutate.mutate({
        country: profile?.allowed_countries[0]?.id,
        days: profile.additional_payment_cut_off_day,
      })
    }
  }, [profile])

  useEffect(() => {
    if (
      postCalculateLastDayMutate?.data?.last_day &&
      currentDate.getTime() >=
        new Date(postCalculateLastDayMutate?.data?.last_day).getTime()
    ) {
      const nextDate = new Date()
      nextDate.setMonth(currentDate.getMonth() + 1)
      nextDate.setDate(1)
      setMinDate(nextDate)
    }
  }, [postCalculateLastDayMutate.data])

  useEffect(() => {
    if (starts_at_watch) {
      setValue('recurrence_ending_month', undefined)
    }
  }, [starts_at_watch])

  const options = useMemo(() => {
    return addPmtListMutation.data?.results.length
      ? addPmtListMutation.data.results.map((e) => ({
          value: e.id,
          label: e.name,
        }))
      : paymentTypes
  }, [addPmtListMutation.data])

  return (
    <Modal show onHide={onHide} centered>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">
          New Additional Payment
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <StyledForm
          noValidate
          id={formId}
          className="d-flex flex-column"
          onSubmit={handleSubmit(submit)}
        >
          <div className="row">
            <div className="col">
              <div className="remo-form-input">
                <RadioButtonHorizontal
                  {...register('is_recurrent')}
                  isHorizontal
                  label="One-time"
                  addText="Suitable for bonuses and one-time rewards"
                  options={[{ text: '', value: false }]}
                />
                {errors.currency && (
                  <Typography className="text_regular__14 color_red">
                    {errors.currency.message}
                  </Typography>
                )}
              </div>
            </div>
            <div className="col">
              <div className="remo-form-input">
                <RadioButtonHorizontal
                  {...register('is_recurrent')}
                  addText="Suitable for recurring payments such as bonuses"
                  label="Recurring"
                  options={[{ text: '', value: true }]}
                />
                {errors.currency && (
                  <Typography className="text_regular__14 color_red">
                    {errors.currency.message}
                  </Typography>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="ap_type_id"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="CreateModal-888084"
                      {...field}
                      label="Payment type"
                      options={options}
                    />
                  )
                }}
              />
              {errors.ap_type_id && (
                <Typography className="text_regular__14 color_red">
                  {errors.ap_type_id.message}
                </Typography>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="amount"
                rules={{
                  required: 'Field is required',
                  validate: {
                    minlength: (v) =>
                      /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) ||
                      'Only 2 digits allowed after decimal point',
                  },
                }}
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    isRequired
                    label="Amount"
                    placeholder="Enter the adjustment amount"
                    onChange={setCurrencyChangeEvent(field.onChange)}
                  />
                )}
              />
              {errors.amount && (
                <Typography className="text_regular__14 color_red">
                  {errors.amount.message}
                </Typography>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="currency"
                rules={{ required: 'Currency is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="CreateModal-6C7C58"
                      {...field}
                      label="Currency"
                      options={currencies.map((country) => ({
                        value: country.id,
                        label: country.name || country.short_code,
                      }))}
                    />
                  )
                }}
              />
              {errors.currency && (
                <Typography className="text_regular__14 color_red">
                  {errors.currency.message}
                </Typography>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="tax"
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    label="Employer tax & contributions"
                    placeholder="Enter the tax"
                    onChange={setCurrencyChangeEvent(field.onChange)}
                  />
                )}
              />

              {errors.tax && (
                <Typography className="text_regular__14 color_red">
                  {errors.tax.message}
                </Typography>
              )}
            </div>
          </div>
          {(total && (
            <div style={{ marginBottom: '32px' }}>
              <Input
                disabled
                value={total}
                label="Total"
                placeholder="Tax"
                class
              />
              <Typography style={{ fontSize: '12px', color: '#8A96A1' }}>
                The estimated total amount, including employer taxes and
                contributions
              </Typography>
            </div>
          )) ||
            ''}

          <div className="row">
            <div className="col-12">
              <Input
                data-testid="CreateModal-D47C21"
                {...register('name', { required: 'Field is required' })}
                isRequired
                label="Payment name"
                placeholder="Title"
              />
              {errors.name && (
                <Typography className="text_regular__14 color_red">
                  {errors.name.message}
                </Typography>
              )}
            </div>
            <div className="col-12">
              <TextArea
                placeholder="Description..."
                {...register('description')}
              />
              {errors.description && (
                <Typography className="text_regular__14 color_red">
                  {errors.description.message}
                </Typography>
              )}
            </div>
          </div>
          {isRecurrentWatch === 'true' && (
            <>
              <Typography className="heading_semibold__24">
                Select period
              </Typography>
              <div className="row">
                <div className="col-6 pr-2">
                  <div className="w-100 remo-form-input">
                    <Controller
                      control={control}
                      name="recurrence_starting_month"
                      rules={{ required: 'Month is required' }}
                      render={({ field }) => (
                        <Select
                          data-testid="CreateModal-3T4425"
                          {...field}
                          label="From"
                          isRequired
                          options={monthOptions(minDate)}
                        />
                      )}
                    />
                    {errors.recurrence_starting_month && (
                      <Typography className="text_regular__14 color_red">
                        {errors.recurrence_starting_month.message}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className="col-6 pl-2">
                  <div className="w-100 remo-form-input">
                    <Controller
                      control={control}
                      name="recurrence_ending_month"
                      rules={{ required: 'Month is required' }}
                      render={({ field }) => (
                        <Select
                          data-testid="CreateModal-6R979A"
                          {...field}
                          label="To"
                          isRequired
                          isDisabled={!starts_at_watch}
                          defaultValue={undefined}
                          options={monthOptions(
                            starts_at_watch?.value || minDate
                          )}
                        />
                      )}
                    />
                    {errors.recurrence_ending_month && (
                      <Typography className="text_regular__14 color_red">
                        {errors.recurrence_ending_month.message}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Controller
                    control={control}
                    name="recurrence_frequency"
                    rules={{ required: 'Field is required' }}
                    render={({ field }) => {
                      return (
                        <Select
                          data-testid="CreateModal-5A590F"
                          {...field}
                          label="Frequency"
                          options={recurrenceFrequency}
                        />
                      )
                    }}
                  />
                  {errors.recurrence_frequency && (
                    <Typography className="text_regular__14 color_red">
                      {errors.recurrence_frequency.message}
                    </Typography>
                  )}
                </div>
              </div>
            </>
          )}
        </StyledForm>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="CreateModal-ADF71C"
          priority="secondary"
          size="small"
          type="button"
          onClick={onHide}
        >
          Close
        </Button>
        <Button
          data-testid="CreateModal-A5065F"
          type="submit"
          form={formId}
          size="small"
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
