import { CountrySalaryTable } from '@components/min-country-salary-table/min-country-salary-table.component'
import { Alert, Box, Button } from '@mui/material'
import { getCountry } from '@remoteam-front/services/country.service'
import { getMinSalaries } from '@remoteam-front/services/country-salary.service'
import { DotWave } from '@uiball/loaders'
import { useQuery } from 'react-query'

type Props = {
  countryId: number
  onNext(): void
}

export const MinCountrySalary = ({ countryId, onNext }: Props) => {
  const countryQuery = useQuery([getCountry.key, countryId], {
    enabled: !!countryId,
    queryFn: () => getCountry.fetch(String(countryId)),
  })

  const countrySalaryQuery = useQuery([getMinSalaries.key], {
    enabled: !!countryId,
    queryFn: () => getMinSalaries.fetch(countryId),
  })

  if (countrySalaryQuery.isLoading || countryQuery.isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <DotWave />
      </Box>
    )
  }

  if (countrySalaryQuery.data && countryQuery.data) {
    return (
      <Box display="flex" flexDirection="column" gap={4}>
        <Alert severity="info">
          If you spot any mistake in the tables below, please reach out to your
          Partner Relationship manager at RemoFirst team.
        </Alert>
        <CountrySalaryTable
          currency={countryQuery.data.currency}
          data={countrySalaryQuery.data}
        />
        <Box alignSelf="center">
          <Button variant="contained" onClick={onNext}>
            Next
          </Button>
        </Box>
      </Box>
    )
  }

  return null
}
