import Typography from '@atoms/Typography/Typography'
import React, { memo, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import styled from 'styled-components'

import Form, { expenseTypes, paymentTypes } from './Form'

const Styled = styled.div`
  margin: 8px 0;
`

const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: ${(p) => (p.open ? '#F9F9F9' : '#fff')};
`

const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0 0 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`

const StyledIcon = styled(MdChevronRight)`
  transform: ${(p) => p.open && 'rotate(90deg)'};
  color: #b2b2b2;
  margin-right: 8px;
`

const Item = memo(
  ({
    payment,
    paymentData,
    isLoading,
    onDelete,
    onSave,
    expandedId,
    setPaymentId,
  }) => {
    const isOpen = expandedId === payment.id

    const handleToggle = (id) => () => setPaymentId(isOpen ? null : id)

    return (
      <Styled key={payment.id}>
        <Header className="d-flex align-items-center" open={isOpen}>
          <div
            className="flex-grow-1"
            role="button"
            aria-hidden
            onClick={handleToggle(payment.id)}
          >
            <StyledIcon open={isOpen} />
            <Typography
              as="span"
              className={`${
                isOpen ? 'heading_semibold__18' : 'text_medium__14'
              }`}
            >
              {payment.source === 'EXPENSE_MANAGEMENT'
                ? expenseTypes.filter(
                    (type) => type.value === payment?.ap_type
                  )[0]?.label
                : paymentTypes.filter(
                    (type) => type.value === payment?.ap_type
                  )[0]?.label}
            </Typography>
          </div>
        </Header>
        <Body open={isOpen}>
          <Form
            data={payment}
            paymentData={paymentData}
            isLoading={isLoading}
            onClose={handleToggle(null)}
            onSave={onSave}
            onDelete={() => onDelete(payment.id)}
          />
        </Body>
      </Styled>
    )
  }
)

const Accordion = ({
  paymentData,
  items,
  isLoading,
  onSave,
  onDelete,
  currency,
}) => {
  const [expandedItemId, setExpandedItemId] = useState(null)

  return items.map((item) => (
    <Item
      key={item.id}
      isLoading={isLoading}
      paymentData={paymentData}
      payment={item}
      currency={currency}
      onDelete={onDelete}
      onSave={(body) => onSave({ id: item.id, body })}
      expandedId={expandedItemId}
      setPaymentId={setExpandedItemId}
    />
  ))
}

Accordion.displayName = 'Accordion'

export default memo(Accordion)
