import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Currency } from '@remoteam-front/types'
import { CountryMinSalary } from '@remoteam-front/types/v2/country-salary.types-v2'
import {
  capitalizeFirstLetter,
  formatDate,
  isLastIndex,
  mapEnum,
  splitNumber,
} from '@remoteam-front/utils'
import { mapCurrency } from '@remoteam-front/utils/currency'

type Props = {
  data: CountryMinSalary[]
  currency: Currency
}

export const CountrySalaryTable = ({ data, currency }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">Effective start date</TableCell>
          <TableCell align="left">Effective end date</TableCell>
          <TableCell align="left">Region</TableCell>
          <TableCell align="left">Min hourly rate (full-time)</TableCell>
          <TableCell align="left">No. of payments per year</TableCell>
          <TableCell align="left">Standard work hours per week</TableCell>
          <TableCell align="left">Min weekly wage</TableCell>
          <TableCell align="left">Min monthly salary</TableCell>
          <TableCell align="left">Min annual salary</TableCell>
          <TableCell align="left">Mini hourly rate (part-time)</TableCell>
          <TableCell align="left">Part-time calculation</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(
          ({
            id,
            effective_start_date,
            effective_end_date,
            regions,
            minimum_hourly_rate,
            salary_payments_per_year,
            part_time_salary_calculation_type,
            country,
            minimum_weekly_salary,
            minimum_annual_salary,
            minimum_monthly_salary,
            part_time_minimum_hourly_rate,
          }) => (
            <TableRow key={id}>
              <TableCell>
                {effective_start_date ? formatDate(effective_start_date) : '-'}
              </TableCell>
              <TableCell>
                {effective_end_date ? formatDate(effective_end_date) : '-'}
              </TableCell>
              <TableCell>
                {regions.length
                  ? regions.map(({ id, name }, i, arr) => (
                      <p key={id} style={{ marginBottom: '2px' }}>{`${name}${
                        !isLastIndex(arr, i) ? ', ' : ''
                      }`}</p>
                    ))
                  : '-'}
              </TableCell>
              <TableCell>{`${splitNumber(minimum_hourly_rate)} ${mapCurrency(
                currency
              )}`}</TableCell>
              <TableCell>{salary_payments_per_year}</TableCell>
              <TableCell>
                {country.work_schedule.full_time_suggested_work_hours_per_week}
              </TableCell>
              <TableCell>{`${splitNumber(minimum_weekly_salary)} ${mapCurrency(
                currency
              )}`}</TableCell>

              <TableCell>{`${splitNumber(minimum_monthly_salary)} ${mapCurrency(
                currency
              )}`}</TableCell>

              <TableCell>{`${splitNumber(minimum_annual_salary)} ${mapCurrency(
                currency
              )}`}</TableCell>

              <TableCell>{`${splitNumber(
                part_time_minimum_hourly_rate
              )} ${mapCurrency(currency)}`}</TableCell>
              <TableCell>
                {mapEnum(
                  capitalizeFirstLetter(part_time_salary_calculation_type)
                )}
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  )
}
