import './style.scss'

import Button from '@atoms/Button/Button'
import NotFound from '@atoms/NotFound/NotFound'
import Select from '@atoms/Select/Select'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { PAYROLL_STATUSES } from '@core/constants'
import { useSearchParams } from '@core/hooks/useRouteQuery'
import { approvedPayrollsField } from '@pages/flagPayrollCompanyList/mock'
import { getCompaniesShort } from '@services/company.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  approveFlowPayroll,
  getPayrollListCompany,
  getPayrollListNeedChange,
} from '../../services/payroll.service'
import ChangesAccordion from './ChangesAccordion'
import NewPayrollTable from './new-table/new-table.component'

const FilterStates = [
  PAYROLL_STATUSES.AWAITING_PAYMENT,
  PAYROLL_STATUSES.CREATED,
  PAYROLL_STATUSES.PAID,
  PAYROLL_STATUSES.CANCELED,
]

export default function NewPayrollDetailPage() {
  const history = useHistory()
  const { params, setParams } = useSearchParams()
  const [status, setStatus] = useState(params.get('status') || '')
  const [search, setSearch] = useState(params.get('search') || '')
  const activeTab = params.get('tab') || 'pending'

  const {
    data: companyPayrollList,
    refetch,
    isFetching,
  } = useQuery(['getPayrollListCompany', search, activeTab], {
    queryFn: () =>
      getPayrollListCompany({ state: activeTab, limit: 1000, company: search }),
  })

  const { data: companiesData } = useQuery(['CompaniesShort'], {
    queryFn: () => getCompaniesShort({ limit: 1000 }),
  })

  const approvePayrollRequest = useMutation('approveFlowPayroll', {
    mutationFn: (payload) => approveFlowPayroll(payload),
    onSuccess: () => {
      toast.success('Successfully updated')
      refetch()
    },
  })

  const { data: listNeedChange, isLoading: changeLoading } = useQuery(
    'payrollsListNeedChange',
    {
      queryFn: () => getPayrollListNeedChange(),
    }
  )

  const onRowApprovedClick = ({ company_id }) => {
    history.push(`/pages/flag_payrolls/${company_id}/approved`)
  }

  const handleTabChange = (tab) => {
    setParams({ tab, search })
  }

  const handleCompanyChange = (option) => {
    const val = option?.value || null
    setSearch(val)
    setParams({ search: val || '', status })
  }
  const handleApproveAll = () => {
    approvePayrollRequest.mutate({ for_all: true })
  }

  return (
    <div className="customer-payroll">
      <div className="d-flex align-items-center mb-4">
        <Typography className="heading_semibold__24">Payroll</Typography>
      </div>
      <Tabs onTabChange={handleTabChange} selectedTab={activeTab}>
        <Tab tabId="pending" title="Pending">
          <div>
            <div className="d-flex mb-4 align-items-center gap-3 justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div style={{ width: 250 }}>
                  <Select
                    placeholder="Select company"
                    value={search}
                    onChange={handleCompanyChange}
                    options={companiesData?.results?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                    isClearable
                  />
                </div>
              </div>
              <div style={{ width: 190 }}>
                <Button
                  style={{ height: '32px' }}
                  size="small"
                  className="px-2 pl-0"
                  onClick={handleApproveAll}
                >
                  Approve All
                </Button>
              </div>
            </div>
            <NewPayrollTable
              data={companyPayrollList}
              refetch={refetch}
              isLoading={isFetching}
            />
          </div>
        </Tab>
        <Tab tabId="approved" title="Approved">
          {/* eslint-disable-next-line no-nested-ternary */}
          {isFetching && !companyPayrollList ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : companyPayrollList?.data.length > 0 ? (
            <CustomTable
              fields={approvedPayrollsField}
              data={companyPayrollList?.data}
              loading={isFetching}
              onRowClick={onRowApprovedClick}
            />
          ) : (
            <NotFound
              illustration="/assets/img/ec-empty-shopping-cart.png"
              title="No pending payroll"
            />
          )}
        </Tab>
        <Tab
          tabId="change_requests"
          title="Requested Change"
          count={listNeedChange?.count}
        >
          {changeLoading && listNeedChange?.length === 0 ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div>
              <ChangesAccordion
                month={listNeedChange?.month}
                payments={listNeedChange?.payrolls}
              />
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  )
}
