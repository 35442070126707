import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { Loader } from '@components/loader/loader.component'
import {
  TimeOffAccrualRule,
  TimeOffAnnualDaysRule,
  TimeOffSickLeaveDaysRule,
} from '@core/constants'
import { useToast } from '@core/hooks/useErrorNotification'
import { PaidBereavementModal } from '@pages/employeeDetailPage/libs/TimeOff/PaidBereavementModal'
import { PaidParentalModal } from '@pages/employeeDetailPage/libs/TimeOff/PaidParentalModal'
import { PaidSickLeaveModal } from '@pages/employeeDetailPage/libs/TimeOff/PaidSickLeaveModal'
import { PaidTimeOffModal } from '@pages/employeeDetailPage/libs/TimeOff/PaidTimeOffModal'
import {
  getContractsPaidLeaves,
  postContractsPaidLeaves,
} from '@services/contract.service'
import {
  fetchCountryPaidLeavesById,
  fetchCountrySpecificPaidById,
} from '@services/countries.service'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import TimeOffCarryOverForm from './TimeOffCarryOverForm'

const Container = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
`

export const TIME_OFF_INFO_VIEW_FORM = 'TIME_OFF_INFO_VIEW_FORM'

export default function TimeOffEmployee({ employee, refetchEmployee }) {
  const { successAlert } = useToast()
  const { value: valuePaidTimeOff, toggle: togglePaidTimeOff } =
    useBoolean(false)
  const { value: valuePaidSick, toggle: togglePaidSick } = useBoolean(false)
  const { value: valuePaidParental, toggle: togglePaidParental } =
    useBoolean(false)
  const { value: valueBereavement, toggle: toggleBereavement } =
    useBoolean(false)

  const { data: countryTimeoffPolicyData, isLoading } = useQuery(
    [fetchCountryPaidLeavesById.key, employee?.working_country.id],
    {
      queryFn: () => fetchCountryPaidLeavesById(employee?.working_country.id),
    }
  )

  const isCarryOverAllowedByCountry = countryTimeoffPolicyData?.allow_carryover

  const { data, refetch } = useQuery(['getContractsPaidLeaves', employee?.id], {
    queryFn: () => getContractsPaidLeaves(employee?.id),
  })

  const updateContractsPaidLeaves = useMutation({
    mutationFn: (payload) => postContractsPaidLeaves(payload.id, payload.data),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated!')
      if (valuePaidSick) togglePaidSick()
      if (valueBereavement) toggleBereavement()
      if (valuePaidParental) togglePaidParental()
      if (valuePaidTimeOff) togglePaidTimeOff()
    },
  })

  const { data: countrySpecificPaidLeaves } = useQuery(
    ['fetchCountrySpecificPaidById', employee?.working_country?.id],
    {
      queryFn: () =>
        fetchCountrySpecificPaidById(employee?.working_country?.id),
      enabled: !!employee?.working_country?.id,
    }
  )

  const onSubmit = (payload) => {
    updateContractsPaidLeaves.mutate({ id: employee?.id, data: payload })
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Typography className="heading_semibold__20 color_black">
          Time-off
        </Typography>
      </div>
      {data && (
        <div className="col-8 mb-3">
          <Container>
            <InfoMessage
              type="secondary"
              message="The parameters in this section are defined in the employee agreement and cannot be modified without an amendment to the agreement."
              fullWidth
            />
            <form id={TIME_OFF_INFO_VIEW_FORM}>
              <div className="w-100 remo-form-input">
                <Select
                  label="Annual paid time off days"
                  isDisabled
                  value={employee?.paid_leave?.annual_days_rule}
                  options={[
                    {
                      value: TimeOffAnnualDaysRule.Statutory,
                      label: `Statutory annual leave by country${
                        countryTimeoffPolicyData
                          ? ` - ${countryTimeoffPolicyData?.statutory_annual_days} days`
                          : ''
                      }`,
                    },
                    {
                      value: TimeOffAnnualDaysRule.Recommended,
                      label: `Recommended annual leave${
                        countryTimeoffPolicyData
                          ? ` - ${countryTimeoffPolicyData?.recommendation_by_remofirst_days} days`
                          : ''
                      }`,
                    },
                    {
                      value: TimeOffAnnualDaysRule.TopOfMarket,
                      label: `Top of market annual leave${
                        countryTimeoffPolicyData
                          ? ` - ${countryTimeoffPolicyData?.recommendation_by_market_days} days`
                          : ''
                      }`,
                    },
                  ]}
                />
              </div>

              <div className="w-100 remo-form-input">
                <Select
                  label="Sick leave days"
                  isDisabled
                  value={employee?.paid_leave?.sick_leave_days_rule}
                  options={[
                    {
                      value: TimeOffSickLeaveDaysRule.Statutory,
                      label: `Statutory sick leave by country${
                        countrySpecificPaidLeaves
                          ? ` - ${countrySpecificPaidLeaves?.sick_days} days`
                          : ''
                      }`,
                    },
                    {
                      value: TimeOffSickLeaveDaysRule.Unlimited,
                      label: 'Unlimited',
                    },
                  ]}
                />
              </div>

              <div className="w-100 remo-form-input">
                <RadioButton
                  label="Paid time off accrual rule"
                  layout="vertical"
                  disabled
                  value={employee?.paid_leave?.accrual_rule}
                  column
                  options={[
                    {
                      id: TimeOffAccrualRule.AllAtOnce,
                      text: 'All at once: employee will receive all their Annual Days balance immediately',
                      value: TimeOffAccrualRule.AllAtOnce,
                    },
                    {
                      id: TimeOffAccrualRule.Accrued,
                      text: 'Accrued: employee will accumulate their annual days off over time',
                      value: TimeOffAccrualRule.Accrued,
                    },
                  ]}
                />
              </div>
            </form>
          </Container>
        </div>
      )}
      <div className="col-8">
        <Container>
          <div className="d-flex flex-column">
            {isCarryOverAllowedByCountry ? (
              <InfoMessage
                type="success"
                message="You can configure the carryover settings below. By default, these settings inherit your company’s time-off policy, but you can override them for individual employees."
                fullWidth
              />
            ) : (
              <InfoMessage
                message={`Carryover is not supported in ${employee?.working_country?.name}`}
                fullWidth
              />
            )}
            <TimeOffCarryOverForm
              employee={employee}
              refetchEmployee={refetchEmployee}
              isCarryOverAllowedByCountry={isCarryOverAllowedByCountry}
            />
          </div>
        </Container>
      </div>
      {valuePaidParental && data && (
        <PaidParentalModal
          title="Paid parental leaves"
          paidParentalLeave={data.leave_days.paid_parental_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={togglePaidParental}
          onSubmit={onSubmit}
        />
      )}
      {valueBereavement && data && (
        <PaidBereavementModal
          title="Paid bereavement leaves"
          paidBereavementLeave={data.leave_days.paid_bereavement_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={toggleBereavement}
          onSubmit={onSubmit}
        />
      )}
      {valuePaidTimeOff && data && (
        <PaidTimeOffModal
          title="Paid time - off days"
          onSubmit={onSubmit}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={togglePaidTimeOff}
          paidLeave={data.leave_days.paid_leave}
        />
      )}
      {valuePaidSick && data && (
        <PaidSickLeaveModal
          title="Paid sick leaves"
          paidSickLeave={data.leave_days.paid_sick_leave}
          loading={updateContractsPaidLeaves.isLoading}
          onClose={togglePaidSick}
          onSubmit={onSubmit}
        />
      )}
    </div>
  )
}
