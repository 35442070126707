import Button from '@atoms/Button/Button'
import React from 'react'
import { Accept, useDropzone } from 'react-dropzone'

import { MAX_FILE_SIZE_MB } from '../attachment.utils'
import { Styled } from './attachment-dropzone.styles'

export const AttachmentDropzone = ({
  multiple,
  accept,
  isRequired,
  onDrop,
  errorText = '',
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    accept,
    onDrop,
  })

  return (
    <div>
      <Styled.Root error={!!errorText}>
        <Styled.Container {...getRootProps()}>
          <input
            data-testid="attachment-dropzone-89C9A7"
            {...getInputProps()}
          />

          <Styled.Content>
            {isDragActive ? (
              <Styled.Title>
                Drop the {multiple ? 'files' : 'file'} here ...
              </Styled.Title>
            ) : (
              <Styled.Title>
                Drag and drop your {multiple ? 'files' : 'file'} here{' '}
                {isRequired && <span className="text-danger ml-1">*</span>}
              </Styled.Title>
            )}
            <Styled.Description>
              Supported file formats: pdf, jpeg, docx; file size up to{' '}
              {MAX_FILE_SIZE_MB} MB
            </Styled.Description>
          </Styled.Content>
          <Button
            data-testid="attachment-dropzone-B26D3C"
            priority="secondary"
            size="small"
            type="button"
          >
            Select file
          </Button>
        </Styled.Container>
      </Styled.Root>
      {errorText && <Styled.Error>{errorText}</Styled.Error>}
    </div>
  )
}
