import Typography from '@atoms/Typography/Typography'
import React, { memo } from 'react'
import styled from 'styled-components'

import InvoiceForm from './InvoiceForm'

const Styled = styled.div`
  margin: 8px 0;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: 8px 8px 0 0;
  padding: 16px;
  outline: none;
  background: #f9f9f9;
`
const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0 0 8px 8px;
`

const Item = memo(({ payments, isLoading, onSave }) => {
  return (
    <Styled>
      <Header className="d-flex align-items-center">
        <div className="d-flex w-100 align-items-center">
          <div className="d-flex w-100 justify-content-between my-2">
            <Typography className="heading_semibold__18">Base</Typography>
          </div>
        </div>
      </Header>
      <Body>
        <InvoiceForm
          payments={payments}
          isLoading={isLoading}
          onSave={onSave}
        />
      </Body>
    </Styled>
  )
})

const MyAccordion = ({ payments, isLoading, onSave }) => {
  return (
    <Item
      key={payments.id}
      isLoading={isLoading}
      payments={payments}
      onSave={(body) => onSave({ id: payments.id, body })}
    />
  )
}

MyAccordion.displayName = 'Accordion'

export default memo(MyAccordion)
