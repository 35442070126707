export const PAYMENT_FREQUENCY = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'semi_monthly', label: 'Semi-monthly' },
  { value: 'biweekly', label: 'Biweekly' },
]

export const PaymentValue = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  semi_monthly: 'Semi-monthly',
  biweekly: 'Biweekly',
}

export const TEMPLATE_TYPES = {
  JOB_CONTRACT: 'job_contract',
  SALARY_CHANGE: 'salary_change',
  CONTRACT_TERMINATION: 'contract_termination',
  MSA: 'msa',
  APPENDIX: 'appendix',
}

export const EOR_TYPES = {
  TRADITIONAL: 'traditional_eor',
  SAAS: 'saas_eor',
}

export const SIGNATURE_STATES = {
  AWAITING: 'awaiting_signature',
  SIGNED: 'signed',
}

export const PARENTAL_LEAVE_TYPE = {
  MATERNITRY: 'maternity_days',
  PATERNITY: 'paternity_days',
}

export const EMPLOYEE_STATES = {
  ONBOARDING: 'onboarding',
  CREATED: 'created',
  SIGNING: 'signing',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
  BEING_VERIFIED: 'being_verified',
  COMPLETED: 'completed',
  DRAFT: 'draft',
}

export const PAYROLL_STATUSES = {
  CREATED: 'CREATED',
  APPROVED_BY_COMPANY: 'APPROVED_BY_COMPANY',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  DELETED: 'DELETED',
  MOVED_TO_NEXT_MONTH: 'MOVED_TO_NEXT_MONTH',
  INVOICED: 'INVOICED',
}

export const EXPENSE_STATUSES = {
  CREATED: 'CREATED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
}

export const PRONOUNS = {
  SHE_HER: 'she/her',
  HE_HIM: 'he/him',
  THEY_THEM: 'they/them',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
}

export const DOCUMENT_REQUIREMENT = [
  { value: 'mandatory_for_residents', label: 'Mandatory for Residents' },
  { value: 'mandatory_for_nonresidents', label: 'Mandatory for Non-Residents' },
  { value: 'optional_for_everyone', label: 'Optional for Everyone' },
  { value: 'mandatory_for_everyone', label: 'Mandatory for Everyone' },
]

export const TIME_OFF_STATUSES = {
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  TAKEN: 'TAKEN',
}

export const TIME_OFF_STATUSES_MAP = {
  APPROVED: 'Approved',
  CANCELED: 'Canceled',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  TAKEN: 'Taken',
}

export const TIME_OFF_LEAVE_TYPES = {
  VACATION: 'Vacation',
  SICK_LEAVE: 'Sick leave',
  BEREAVEMENT: 'Bereavement',
  PARENTAL_LEAVE: 'Parental leave',
  CAREGIVER_LEAVE: 'Caregiver leave',
  CIVIC_DUTIES: 'Civic duties',
  EDUCATIONAL_LEAVE: 'Educational leave',
  MARRIAGE_LEAVE: 'Marriage leave',
  MILITARY_LEAVE: 'Military leave',
  MILITARY_MEDICAL_EXAMINATION: 'Military medical examination',
  MILITARY_TRAINING_ASSEMBLY: 'Military training assembly',
  RELIGIOUS_OBSERVANCE: 'Religious observance',
  SABBATICAL: 'Sabbatical',
}

export const BALANCE_BREAKDOWN_TYPES = {
  VACATION: 'vacation',
  ADHOC: 'adhoc',
  ANNUAL_ENTITLEMENT: 'annual_entitlement',
  CARRYOVER: 'carryover',
}

export const BALANCE_BREAKDOWN_TYPES_MAP = {
  vacation: 'Vacation',
  adhoc: 'Ad-hoc',
  annual_entitlement: 'Annual entitlement',
  carryover: 'Carryover',
}

export const BALANCE_BREAKDOWN_ACTION_TYPES = {
  USER_GENERATED: 'user_generated',
  AUTO_GENERATED: 'auto_generated',
}

export const BALANCE_BREAKDOWN_ACTION_TYPES_MAP = {
  user_generated: 'User Generated',
  auto_generated: 'Auto Generated',
}

export const USER_ROLES_MAP = {
  company_owner: `Company Admin`,
  'company owner': `Company Admin`,
  company_admin: `Manager`,
  partner_owner: `Partner Admin`,
  partner_manager: `Partner Manager`,
  admin: `System Admin`,
  dummy: `System Admin`,
  employee: `Employee`,
}

export const TimeOffAnnualDaysRule = {
  Statutory: 'statutory',
  Recommended: 'recommended',
  TopOfMarket: 'top_of_market',
}

export const TimeOffAccrualRule = {
  AllAtOnce: 'all_at_once',
  Accrued: 'accrued',
}

export const TimeOffAllowanceRule = {
  ProratedAllowance: 'prorated_allowance',
  FullAllowance: 'full_allowance',
}

export const TimeOffCarryoverRule = {
  Unlimited: 'unlimited',
  Limited: 'limited',
}

export const TimeOffCarryoverExpiry = {
  None: 'none',
  Custom: 'custom',
}

export const TimeOffCarryoverExpiryUnit = {
  Days: 'days',
  Weeks: 'weeks',
  Months: 'months',
  Years: 'years',
}

export const TimeOffSickLeaveDaysRule = {
  Statutory: 'statutory',
  Recommended: 'recommended',
  Unlimited: 'unlimited',
}

export const LeaveType = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
}

export const CONTRACT_JOB_START_DELAY_REASON = {
  client_delay: 'Client delay',
  ee_delay: 'EE delay',
  visa_delay: 'Visa delay',
  deposit_invoice_delay: 'Deposit invoice delay',
  other: 'Other',
}

export const EMPLOYEE_PAY_TYPE = {
  Salaried: 'salaried',
  Hourly: 'hourly',
}

export const EMPLOYEE_PAYMENT_FREQUENCY_MAP = {
  monthly: 'Monthly',
  semi_monthly: 'Semi-monthly',
  weekly: 'Weekly',
  every_two_weeks: 'Every 2 weeks',
}

export const OFFBOARDING_TYPE = {
  Termination: 'termination_company_initiated',
  Resignation: 'resignation_employee_initiated',
}

export const OFFBOARDING_TYPE_MAP = {
  termination_company_initiated: 'Termination - company initiated',
  resignation_employee_initiated: 'Resignation - employee initiated',
}

export const OFFBOARDING_TERMINATION_REASONS = {
  business_closure: 'business_closure',
  reduction_in_operations: 'reduction_in_operations',
  redundancy: 'redundancy',
  set_up_own_entity: 'set_up_own_entity',
  acquisition: 'acquisition',
  performance_issues: 'performance_issues',
  contract_breach: 'contract_breach',
  misconduct: 'misconduct',
  not_the_right_fit: 'not_the_right_fit',
  remofirst_service: 'remofirst_service',
  transfer_to_another_eor: 'transfer_to_another_eor',
  switching_to_contractor: 'switching_to_contractor',
  visa_issues: 'visa_issues',
  end_of_contract: 'end_of_contract',
  relocation: 'relocation',
  transfer_to_another_lp: 'transfer_to_another_lp',
  remofirst_decision: 'remofirst_decision',
}
export const OFFBOARDING_TERMINATION_REASONS_MAP = {
  business_closure: 'Business closure',
  reduction_in_operations: 'Reduction in operations',
  redundancy: 'Redundancy',
  set_up_own_entity: 'Set up own entity',
  acquisition: 'Acquisition',
  performance_issues: 'Performance Issues',
  contract_breach: 'Contract breach',
  misconduct: 'Misconduct',
  not_the_right_fit: 'Not the right fit',
  remofirst_service: 'Remofirst Service',
  transfer_to_another_eor: 'Transfer to another EOR',
  switching_to_contractor: 'Switching to contractor',
  visa_issues: 'Visa Issues',
  end_of_contract: 'End of Contract',
  relocation: 'Relocation',
  transfer_to_another_lp: 'Transfer to another LP',
  remofirst_decision: 'Remofirst decision',
}

export const OFFBOARDING_RESIGNATION_REASONS_MAP = {
  accepted_new_offer: 'Accepted new offer',
  other_personal_reason: 'Other Personal reasons',
  not_the_right_fit: 'Not the right fit',
  switching_to_contractor: 'Switching to contractor',
  relocation: 'Relocation',
  visa_issues: 'Visa Issues',
  end_of_contract: 'End of Contract / Project completion',
  transfer_to_another_eor: 'Transfer to another EOR',
}

export const EMPLOYEE_PERFORMANCE_REASON_BASED_ACTIONS_MAP = {
  clear_instructions:
    'The employee was provided with clear instructions on work expectations',
  coaching_training:
    'You have provided coaching, mentoring, training, or an improvement plan to address the issues.',
  regular_meetings:
    'You have held regular meetings with the employee to address the issues and provide feedback.',
  feedback_delivered:
    'Feedback was officially delivered to the employee, documented, and signed by both the employee and the line manager.',
  warnings_given:
    'You have provided the employee with warnings or advised them of poor attitude, work ethics, etc.',
  evidence_available: 'You have evidence of the said issues.',
  none_taken: 'None of the above took place.',
}

export const EMPLOYEE_OFFBOARDING_LEAVE_TYPES = {
  vacation: 'Vacation',
  sick_leave: 'Sick leave',
  maternity: 'Maternity leave',
  parental: 'Parental leave',
  // pregnancy: 'Pregnancy',
}

export const EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT = {
  worked: 'worked',
  payment_in_lieu: 'payment_in_lieu',
}

export const EMPLOYEE_NOTICE_PERIOD_ARRANGEMENT_MAP = {
  worked: 'Notice should be worked',
  payment_in_lieu: 'Notice should be paid in lieu (garden leave)',
}

export const OFFBOARDING_PTO_COMMUNICATION_METHOD = {
  leaves_logged_on_platform: 'leaves_logged_on_platform',
  provide_pto_record: 'provide_pto_record',
}

export const OFFBOARDING_PTO_COMMUNICATION_METHOD_MAP = {
  leaves_logged_on_platform:
    'All used leaves have been logged and approved on RemoFirst platform',
  provide_pto_record:
    'Provide the employee’s PTO record so our team can reconcile it',
}

export const PROPERTY_RETURN_INSTRUCTION = {
  yes: 'yes',
  not_yet: 'not_yet',
  not_applicable: 'not_applicable',
}

export const HIRING_PLAN_REMOFIRST = {
  next_3_months: 'next_3_months',
  three_to_six_months: 'three_to_six_months',
  six_months_or_later: 'six_months_or_later',
  no_plan: 'no_plan',
}

export const HIRING_PLAN_REMOFIRST_MAP = {
  next_3_months: 'Yes, in the next 3 months',
  three_to_six_months: 'Yes, in 3–6 months',
  six_months_or_later: 'Yes, in 6 months or later',
  no_plan: 'No plan',
}
