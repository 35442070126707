import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import NotFound from '@atoms/NotFound/NotFound'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { PAYROLL_STATUSES } from '@core/constants'
import { useSearchParams } from '@core/hooks/useRouteQuery'
import { searchIcon } from '@core/icons/icons'
import { capitalizeFirstLetter, mapEnum } from '@core/utils'
import { getThirdWorkingDayNotificationLabel } from '@pages/additional-payments/utils'
import { getAdditionalPayments } from '@services/additional-payments.service'
import { getCompanies } from '@services/company.service'
import {
  fetchCreateAdditionPayment,
  fetchDeleteAdditionPayment,
  fetchDeleteAdditionPaymentWithReccuring,
  fetchUpdateAdditionPayment,
} from '@services/payments.service'
import { DotWave } from '@uiball/loaders'
import React, { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean, useDebounce } from 'usehooks-ts'

import AddPaymentInfoModal from './AddPaymentInfoModal'
import CreateModal from './CreateModal'
import DownloadReport from './DownloadReport'
import { payrollsField } from './mock'
import { parserPayrollsBasePayment } from './parse'

const FilterStates = [
  PAYROLL_STATUSES.AWAITING_PAYMENT,
  PAYROLL_STATUSES.CREATED,
  PAYROLL_STATUSES.PAID,
  PAYROLL_STATUSES.CANCELED,
]

const getState = (state) => {
  switch (state) {
    case PAYROLL_STATUSES.AWAITING_PAYMENT:
      return `${PAYROLL_STATUSES.APPROVED_BY_COMPANY},${PAYROLL_STATUSES.AWAITING_PAYMENT}`
    case PAYROLL_STATUSES.PAID:
      return `${PAYROLL_STATUSES.PAID}`
    case PAYROLL_STATUSES.CREATED:
      return `${PAYROLL_STATUSES.CREATED},${PAYROLL_STATUSES.PENDING},${PAYROLL_STATUSES.MOVED_TO_NEXT_MONTH}`
    case PAYROLL_STATUSES.CANCELED:
      return `${PAYROLL_STATUSES.REJECTED},${PAYROLL_STATUSES.CANCELED},${PAYROLL_STATUSES.DELETED}`
    case PAYROLL_STATUSES.INVOICED:
      return `${PAYROLL_STATUSES.INVOICED}`
    default:
      return state
  }
}

export default function AdditionalPaymentsPage() {
  const { params, setParams } = useSearchParams()
  const { value, toggle } = useBoolean(false)
  const { value: valueEdit, toggle: toggleEdit } = useBoolean(false)
  const { value: valueView, toggle: toggleView } = useBoolean(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState(params.get('search') || '')
  const [company, setCompany] = useState(params.get('company') || '')
  const [selectedRow, setSelectedRow] = useState()
  const [recurringType, setRecurringType] = useState(false)
  const [paymentIdForDelete, setPaymentIdForDelete] = useState(null)
  const [paymentIdForCancel, setPaymentIdForCancel] = useState(null)
  const [status, setStatus] = useState(params.get('status') || '')
  const isOpenDownloadCsv = useBoolean(false)
  const debouncedSearch = useDebounce(search, 500)

  const { data: companiesData, isLoading: companiesLoading } = useQuery(
    ['getCompanies'],
    () => getCompanies()
  )

  const { data, refetch, isFetching, isLoading } = useQuery(
    ['getAdditionalPayments', debouncedSearch, status, company, page],
    {
      queryFn: () =>
        getAdditionalPayments({
          search: debouncedSearch,
          limit: 10,
          offset: (page - 1) * 10,
          state_in: getState(status),
          companies: company,
        }),
    }
  )

  const cancelMutate = useMutation({
    mutationFn: ({ id, body }) =>
      fetchDeleteAdditionPaymentWithReccuring({ id, body }),
    onSuccess: () => {
      refetch()
      setPaymentIdForCancel(null)
    },
  })

  const deleteMutate = useMutation({
    mutationFn: (id) => fetchDeleteAdditionPayment(id),
    onSuccess: () => {
      refetch()
      setPaymentIdForDelete(null)
    },
  })

  const createMutate = useMutation({
    mutationFn: (body) => fetchCreateAdditionPayment(body),
    onSuccess: () => {
      refetch()
      toggle()
    },
  })

  const updateMutate = useMutation({
    mutationFn: ({ id, body }) => fetchUpdateAdditionPayment({ id, body }),
    onSuccess: () => {
      refetch()
      toggleEdit()
    },
  })

  const handleSave = useCallback(
    (body) => {
      updateMutate.mutate({
        id: selectedRow.id,
        body: { ...body, amount: Number(body.amount) },
      })
    },
    [updateMutate, selectedRow]
  )

  const handleCreate = useCallback(
    (body) => {
      createMutate.mutate({
        ...body,
        amount: Number(body.amount),
      })
    },
    [createMutate]
  )

  const handleDelete = useCallback(
    (id) => {
      deleteMutate.mutate(id)
    },
    [deleteMutate]
  )

  const handleCancel = useCallback(
    (id) => {
      cancelMutate.mutate({ id, body: { close_recurrence: recurringType } })
    },
    [deleteMutate]
  )
  const onDelete = useCallback(
    (id) => {
      setPaymentIdForDelete(id)
    },
    [deleteMutate]
  )
  const onCancel = useCallback(
    (id) => {
      setPaymentIdForCancel(id)
    },
    [deleteMutate]
  )

  const handleEdit = useCallback((row) => {
    setSelectedRow(row)
    toggleEdit()
  }, [])

  const handleView = useCallback((row) => {
    setSelectedRow(row)
    toggleView()
  }, [])

  const onSelectStatus = (evt) => {
    setStatus(evt?.value || '')
    setParams({ status: evt?.value || '', search })
    setPage(1)
  }

  const onRecurringTypeChange = (e) => {
    setRecurringType(e.target.value)
  }

  const onChangeSearch = (evt) => {
    const val = evt.target.value
    setSearch(val)
    setParams({ search: val, status, company })
    setPage(1)
  }

  const onSelectCompany = (selectedOption) => {
    const selectedCompany = selectedOption?.value || null
    setCompany(selectedCompany)
    setParams({ company: selectedCompany, search, status })
    setPage(1)
  }

  const handleClearAll = () => {
    setSearch('')
    setStatus('')
    setCompany('')
    setParams({ search: '', status: '', company: '' })
    setPage(1)
  }

  const renderTable = React.useMemo(() => {
    return data?.results?.length > 0 ? (
      <div>
        <CustomTable
          fields={payrollsField(handleView, handleEdit, onDelete, onCancel)}
          data={parserPayrollsBasePayment(data?.results)}
          onPage={setPage}
          page={page}
          loading={isFetching}
          total={data.count}
        />
      </div>
    ) : (
      <NotFound
        illustration="/assets/img/payrollEmpty.png"
        title="Add additional payments"
        description="All submitted additional payments will be displayed here"
      />
    )
  }, [data, toggle])

  return (
    <div className="employees-time-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle>Additional payments</PageTitle>
        <div className="d-flex gap-2">
          <Button
            data-testid="AdditionalPayments-0B276D"
            className="ml-2"
            priority="secondary"
            size="small"
            onClick={isOpenDownloadCsv.setTrue}
          >
            Download report
          </Button>
          <Button
            data-testid="AdditionalPayments-363B8F"
            className="ml-2"
            priority="secondary"
            size="small"
            onClick={toggle}
          >
            + Additional payment
          </Button>
        </div>
      </div>
      <div className="d-flex mb-4 align-items-center gap-3">
        <div style={{ width: 250 }}>
          <Input
            data-testid="AdditionalPayments-A4D900"
            placeholder="Employee name"
            type="text"
            onChange={onChangeSearch}
            value={search}
            name="search"
            endIcon={<Icon icon={searchIcon} />}
            styleClass="employees-page-search"
            style={{ fontSize: '14px' }}
          />
        </div>
        <div style={{ width: 190 }}>
          <Select
            data-testid="AdditionalPayments-7A122C"
            placeholder="Select status"
            value={status || undefined}
            onChange={onSelectStatus}
            isClearable
            options={FilterStates.map((state) => {
              return {
                value: state,
                label: mapEnum(capitalizeFirstLetter(state)),
              }
            })}
          />
        </div>

        <div style={{ width: 190 }}>
          <Select
            type="text"
            showSearch
            data-testid="AdditionalPayments-company-filter"
            placeholder="Company"
            value={company}
            onChange={onSelectCompany}
            isClearable
            options={
              companiesData?.results?.map((comp) => ({
                value: comp.id,
                label: comp.name,
              })) || []
            }
            isDisabled={companiesLoading}
          />
        </div>

        <div>
          <Button
            data-testid="AdditionalPayments-ClearAll"
            onClick={handleClearAll}
            priority="secondary"
            size="small"
          >
            Clear all
          </Button>
        </div>
      </div>
      <NotificationBlock
        style={{
          width: '45%',
          backgroundColor: '#EBF5FC',
          fontSize: '12px',
          fontWeight: '500',
          color: '#4C92C7',
        }}
        variant="blue"
        text={getThirdWorkingDayNotificationLabel()}
      />
      {isLoading || isFetching ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <div className="expense-table-box">
          <div className="expense-table-wrap">{renderTable}</div>
        </div>
      )}
      {!!paymentIdForDelete && (
        <Confirm
          title="Delete Payment"
          isLoading={deleteMutate.isLoading}
          onCancel={() => setPaymentIdForDelete(null)}
          onYes={() => handleDelete(paymentIdForDelete)}
        >
          <Typography>
            Are you sure that you want to delete this additional payment?
          </Typography>
        </Confirm>
      )}
      {!!paymentIdForCancel && (
        <Confirm
          title="Cancel additional payment"
          isLoading={cancelMutate.isLoading}
          onCancel={() => setPaymentIdForCancel(null)}
          onYes={() => handleCancel(paymentIdForCancel)}
        >
          <RadioButton
            label="Please select cancelation setting"
            onChange={onRecurringTypeChange}
            value={recurringType}
            isRequired
            column
            layout="vertical"
            options={[
              {
                id: 'recurring',
                value: 'false',
                text: 'Current payment only',
              },
              {
                id: 'non_recurring',
                value: 'true',
                text: 'Current and all future recurring payments',
              },
            ]}
          />
        </Confirm>
      )}
      {value && data && (
        <CreateModal
          isLoading={createMutate.isLoading}
          isSuccess={createMutate.isSuccess}
          onHide={toggle}
          currency={data?.contract_currency}
          onCreate={handleCreate}
        />
      )}
      {valueEdit && selectedRow && (
        <CreateModal
          isLoading={updateMutate.isLoading}
          isSuccess={updateMutate.isSuccess}
          onHide={toggleEdit}
          isEdit
          selectedItem={selectedRow}
          currency={data?.contract_currency}
          onCreate={handleSave}
        />
      )}
      {valueView && (
        <AddPaymentInfoModal
          addPayment={selectedRow}
          onCloseClick={toggleView}
        />
      )}
      {isOpenDownloadCsv.value && (
        <DownloadReport onClose={isOpenDownloadCsv.setFalse} />
      )}
    </div>
  )
}
