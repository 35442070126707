import Confirm from '@atoms/Confirm'
import { Spinner } from '@atoms/Spinner/spinner'
import Typography from '@atoms/Typography/Typography'
import Accordion from '@pages/payrollsDetailEditPage/Accordion'
import {
  fetchDeleteAdditionPayment,
  fetchUpdateAdditionPayment,
} from '@services/payments.service'
import React, { memo, useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import styled from 'styled-components'

const Styled = styled.div`
  margin: 8px 0;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  padding: 16px;
  outline: none;
  background: #f9f9f9;
`
const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0 0 8px 8px;
`

const Item = memo(({ expenses, isLoading, refetch }) => {
  const [paymentIdForDelete, setPaymentIdForDelete] = useState(null)

  const updateMutate = useMutation({
    mutationFn: ({ id, body }) => fetchUpdateAdditionPayment({ id, body }),
    onSuccess: () => {
      refetch()
    },
  })

  const deleteMutate = useMutation({
    mutationFn: (id) => fetchDeleteAdditionPayment(id),
    onSuccess: () => {
      refetch()
      setPaymentIdForDelete(null)
    },
  })

  const handleSave = useCallback(
    ({ id, body }) => {
      updateMutate.mutate({
        id,
        body: { ...body, amount: Number(body.amount) },
      })
    },
    [updateMutate]
  )

  const handleDelete = useCallback(
    (id) => {
      deleteMutate.mutate(id)
    },
    [deleteMutate]
  )

  return (
    <Styled>
      <Header
        style={
          expenses.length
            ? { borderRadius: '8px 8px 0 0' }
            : { borderRadius: '8px' }
        }
      >
        <div className="d-flex w-100 justify-content-between align-items-center">
          <Typography className="heading_semibold__18">Expenses</Typography>
        </div>
      </Header>
      {expenses.length > 0 && (
        <Body style={{ padding: '2rem' }}>
          <Spinner isFetching={isLoading}>
            <Accordion
              items={expenses}
              isLoading={updateMutate.isLoading}
              onDelete={(id) => setPaymentIdForDelete(id)}
              onSave={handleSave}
            />
          </Spinner>
        </Body>
      )}
      {!!paymentIdForDelete && (
        <Confirm
          title="Cancel expense"
          isLoading={deleteMutate.isLoading}
          onCancel={() => setPaymentIdForDelete(null)}
          onYes={() => handleDelete(paymentIdForDelete)}
        >
          <Typography>
            Are you sure you want to remove this payment from company invoice?
          </Typography>
        </Confirm>
      )}
    </Styled>
  )
})

const ExpensesBlock = ({ expenses, isLoading, payrollId, refetch }) => {
  return (
    <Item
      expenses={expenses}
      isLoading={isLoading}
      payrollId={payrollId}
      refetch={refetch}
    />
  )
}

ExpensesBlock.displayName = 'ExpensesBlock'

export default memo(ExpensesBlock)
